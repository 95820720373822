import {
  useRef,
  useState,
  useEffect,
  type RefObject,
  type PropsWithChildren,
  type CSSProperties,
} from "react";

const styles = {
  width: 1366,
  height: 768,
  position: "absolute",
  top: "50%",
  left: "50%",
  transformOrigin: "0 0",
  backgroundColor: "black",
} as CSSProperties;

type ScaledSlideContainerProps = {
  containerRef: RefObject<HTMLDivElement>;
} & PropsWithChildren;

const ScaledSlideContainer = ({
  children,
  containerRef,
}: ScaledSlideContainerProps) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const [transform, setTransform] = useState("");

  useEffect(() => {
    function setScale() {
      if (frameRef.current === null) {
        return;
      }
      const containerDims = containerRef.current
        ? {
            width: containerRef.current.clientWidth,
            height: containerRef.current.clientHeight,
          }
        : { width: window.innerWidth, height: window.innerHeight };
      var scale = Math.min(
        containerDims.width / frameRef.current.clientWidth,
        containerDims.height / frameRef.current.clientHeight
      );
      setTransform(`scale(${scale}) translate(-50%, -50%)`);
    }
    setScale();
    window.addEventListener("resize", setScale);
    window.addEventListener("orientationchange", setScale);

    function prevDefault(ev: Event) {
      ev.preventDefault();
    }
    document.addEventListener("gesturestart", prevDefault);
    document.addEventListener("gesturechange", prevDefault);
    return function cleanup() {
      window.removeEventListener("resize", setScale);
      window.removeEventListener("orientationchange", setScale);
      document.removeEventListener("gesturestart", prevDefault);
      document.removeEventListener("gesturechange", prevDefault);
    };
  });

  return (
    <div ref={frameRef} style={{ ...styles, transform }}>
      {children}
    </div>
  );
};

export default ScaledSlideContainer;
