import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from "@reduxjs/toolkit";
import store, { AppDispatch, type RootState } from "Store";
import {
  addStories,
  removeStory,
  getStories,
  addSequence,
  updateSequence,
  removeSequence,
} from "Store/stories";
import {
  isOfflineFirst,
  isMarketingSuiteViewer,
  isWKWebView,
  dataProvider,
  isElectronPresenter,
} from "config";
import type { Stories, Story, Sequence } from "Store/stories";

type AvailableUpdateStage =
  | "waiting"
  | "started"
  | "downloading"
  | "installing"
  | "complete"
  | "error";

export type AvailableUpdate = {
  presentation: Story;
  stage: AvailableUpdateStage;
  totalBytesToDownload: number;
  progress: {
    download: number | null;
    install: number | null;
  };
  error: null | string;
  isNew: boolean;
};

type StoriesOfflineManagerState = Array<AvailableUpdate>;

type ProgressError = {
  presentationId: number;
  type: "error";
  message: string;
};

type ProgressPercentage = {
  presentationId: number;
  type: "download" | "install";
  percentage: number;
};

const initialState: StoriesOfflineManagerState = [];

export const STORAGE_KEY = "showhere-stories";
if (isOfflineFirst === false && typeof window !== "undefined") {
  window.localStorage.removeItem(STORAGE_KEY);
}

export const storiesOfflineFirstManagerSlice = createSlice({
  name: "storiesOfflineFirstManager",
  initialState,
  reducers: {
    // NB: this is intended to be called by "storiesOfflineFirstManager/add", hence the private-like prefix
    _add(state, action: PayloadAction<Array<AvailableUpdate>>) {
      action.payload.forEach((availableUpdate) => {
        const queuedUpdate = state.find(
          (update) => update.presentation.id === availableUpdate.presentation.id
        );

        // if this a new update, add it
        if (!queuedUpdate) {
          state.push(availableUpdate);
          return;
        }

        // if the update has started, leave it alone
        if (queuedUpdate.stage !== "waiting") {
          return;
        }

        // overwrite a waiting update with the latest incoming version
        // (this enables skipping over queued versions, e.g. from v2 to v5)
        const queuedUpdateIdx = state.findIndex(
          (update) => update.presentation.id === availableUpdate.presentation.id
        );
        if (queuedUpdateIdx !== -1) {
          state.splice(queuedUpdateIdx, 1);
          state.push(availableUpdate);
        }
      });
    },
    start(state, action: PayloadAction<number>) {
      const updateWaiting = state.find(
        (update) => update.presentation.id === action.payload
      );
      if (!updateWaiting) return;

      updateWaiting.stage = "started";

      try {
        const presentationId = updateWaiting.presentation.id;
        const { version } = updateWaiting.presentation;
        if (isWKWebView) {
          window.webkit?.messageHandlers.mediaHandler.postMessage({
            action: "getPresentationMedia",
            payload: {
              presentationId,
              version,
              apiURL: process.env.REACT_APP_API_URL ?? "",
              apiKey: process.env.REACT_APP_API_KEY ?? "",
              cdnURL: process.env.REACT_APP_API_MEDIA_BASE_URL ?? "",
            },
          });
        } else if (isElectronPresenter) {
          window.electronAPI?.getPresentationMedia({ presentationId, version });
        } else {
          throw new Error("Uncaught `start` target in offline media handler");
        }
      } catch (err) {
        console.log(err);
      }
    },
    setProgress(
      state,
      { payload }: PayloadAction<ProgressError | ProgressPercentage>
    ) {
      const update = state.find(
        ({ presentation }) => presentation.id === payload.presentationId
      );

      if (!update) {
        console.log("Could not call setProgress on pending update");
        console.log({ update, payload });
        return;
      }

      if (payload.type === "error") {
        update.error = payload.message;
        console.error(payload.message);
        return;
      }

      if (update.stage === "started") {
        update.stage = "downloading";
      }

      // sets `progress: { download, install }` values
      update.progress[payload.type] = payload.percentage;

      // advance onto next stage when reaching 100%
      if (payload.percentage === 100) {
        if (payload.type === "download") {
          update.stage = "installing";
        } else if (payload.type === "install") {
          update.stage = "complete";
        }
      }
    },
    remove(state, action: PayloadAction<number>) {
      const idx = state.findIndex(
        (update) => update.presentation.id === action.payload
      );
      if (idx !== -1) state.splice(idx, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeStory, (state, action) => {
      if (isOfflineFirst) {
        try {
          window.webkit?.messageHandlers.mediaHandler.postMessage({
            action: "deletePresentationMedia",
            payload: { presentationId: action.payload.id },
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  },
});

// this `add` acts as an async prepare-method for `_add`
export const add = createAsyncThunk(
  "storiesOfflineFirstManager/add",
  async (presentations: Stories, { dispatch }) => {
    const persistedStories = await getPersistedStories();
    let availableUpdates: Array<AvailableUpdate> = [];

    presentations.forEach(async (presentation) => {
      let totalBytesToDownload = 0;
      if (presentation.downloadSize.media)
        totalBytesToDownload += presentation.downloadSize.media;
      if (presentation.downloadSize.screenCapThumbs)
        totalBytesToDownload += presentation.downloadSize.screenCapThumbs;

      const isNew = !persistedStories.some(
        ({ id, mediaStatus }: Story) =>
          id === presentation.id && mediaStatus === "complete"
      );

      const payload: AvailableUpdate = {
        presentation,
        stage: "waiting" as const,
        totalBytesToDownload,
        progress: {
          download: null,
          install: null,
        },
        error: null,
        isNew,
      };

      availableUpdates.push(payload);
    });

    dispatch(storiesOfflineFirstManagerSlice.actions._add(availableUpdates));
  }
);

export async function getPersistedStories(): Promise<Stories> {
  return await new Promise<Stories>((resolve, reject) => {
    try {
      if (isWKWebView) {
        window.addEventListener(
          "jsonStorageHandler.getOfflinePresentations",
          ((event: CustomEvent<Stories>) => {
            const presentations = event.detail;
            resolve(presentations);
          }) as EventListener,
          { once: true }
        );
        window.webkit.messageHandlers.jsonStorageHandler.postMessage({
          action: "getOfflinePresentations",
        });
      } else {
        const item = window.localStorage.getItem(STORAGE_KEY);
        const presentations = item ? JSON.parse(item) : [];
        resolve(presentations);
      }
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
}

export function setPersistedStories(stories: Stories): void {
  try {
    const sortedStories = [...stories].sort((a, b) => a.order - b.order);
    if (isWKWebView) {
      window.webkit.messageHandlers.jsonStorageHandler.postMessage({
        action: "setOfflinePresentations",
        payload: sortedStories,
      });
    } else {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(sortedStories));
    }
  } catch (err) {
    console.error(err);
  }
}

if (isOfflineFirst && dataProvider.startsWith("api:")) {
  // Once the Redux store has loaded, check media-integrity of initially-loaded stories.
  // If a story's associated media is incomplete for any reason, then re-queue for processing.
  const waitForStore = setInterval(async () => {
    if (!store) return;
    clearInterval(waitForStore);

    const persistedStories = await getPersistedStories();
    let storiesWithIncompleteMedia: Stories = [];
    persistedStories.forEach((story: Story) => {
      if (story.mediaStatus === "incomplete")
        storiesWithIncompleteMedia.push(story);
    });

    if (storiesWithIncompleteMedia.length > 0)
      store.dispatch(add(storiesWithIncompleteMedia));
  }, 100);

  // Check for presentation updates periodically
  const fiveMinsInMs = 300000;
  const thirtySecsInMs = 30000;
  const updateInterval = isMarketingSuiteViewer ? fiveMinsInMs : thirtySecsInMs;
  let isOnline = window.navigator.onLine;
  window.addEventListener("offline", () => {
    isOnline = false;
  });
  window.addEventListener("online", () => {
    isOnline = true;
  });
  setInterval(() => {
    if (isOnline === false) {
      console.log(
        "Blocked periodic presentation updates check for offline client"
      );
      return;
    }
    if (
      dataProvider === "api:auth" &&
      typeof store.getState().auth.user === "undefined" &&
      !isMarketingSuiteViewer
    ) {
      console.log(
        "Blocked periodic presentation updates check for unauthenticated client"
      );
      return;
    }
    console.log("Checking for presentation updates");
    store.dispatch(getStories());
  }, updateInterval);

  // Monitor offline-related media events (iOS + Electron)
  window.addEventListener("OfflineMediaHandler", ((ev: CustomEvent) => {
    const event = ev.detail;
    if (event.type === "progress") {
      store.dispatch(
        setProgress({
          presentationId: event.presentationId,
          type: event.progressType,
          percentage: event.progressPercentage,
        })
      );
    } else if (event.type === "complete") {
      const update = store
        .getState()
        .storiesOfflineFirstManager.find(
          ({ presentation }) => presentation.id === event.presentationId
        );
      if (!update) {
        console.log(
          "Unexpected: an offline-media-handler event of type `complete` failed to match with a pending update"
        );
        return;
      }

      // add the story for use
      store.dispatch(
        addStories([
          {
            ...update.presentation,
            mediaStatus: "complete",
            mediaCompletedAt: Date.now(),
          },
        ])
      );

      // remove the update from the queue
      store.dispatch(remove(event.presentationId));
    } else if (event.type === "error") {
      store.dispatch(
        setProgress({
          presentationId: event.presentationId,
          type: "error",
          message: event.message,
        })
      );
    } else {
      console.log("Unexpected OfflineMediaHandler event-type", event.type);
    }
  }) as EventListener);
}

export const diffLatestPublishedAgainstOfflineCached = createAsyncThunk<
  void,
  Stories,
  { dispatch: AppDispatch; state: RootState }
>(
  "storiesOfflineFirstManager/diffLatestPublishedAgainstOfflineCached",
  (latestPublished, { dispatch, getState }) => {
    const offlineCached = getState().stories.entities;
    const selectedSequenceId = getState().structure.sequenceId;

    const newStories: Stories = []; // includes updates to existing stories
    const archivedStories: Stories = [];
    const newSequences: Array<{ story: Story; sequence: Sequence }> = [];
    const updatedSequences: Array<{ story: Story; sequence: Sequence }> = [];
    const archivedSequences: Array<{ story: Story; sequence: Sequence }> = [];
    const queuedUpdates: Stories = [];

    // delete stories which are cached offline but not returned from the API
    offlineCached.forEach((offlineStory) => {
      const isArchived =
        latestPublished.some(
          (publishedStory) => offlineStory.id === publishedStory.id
        ) === false;
      if (isArchived) {
        archivedStories.push(offlineStory);
      }
    });

    // see which stories are new or updates to existing,
    // and then queue the relevant action depending on context
    latestPublished.forEach((latestPublishedStory) => {
      const offlineCachedStory = offlineCached.find(
        ({ id }) => id === latestPublishedStory.id
      );

      let storyEvent = null;
      if (!offlineCachedStory && latestPublishedStory.archived === false) {
        storyEvent = "new";
      } else if (
        offlineCachedStory &&
        latestPublishedStory.version > offlineCachedStory.version
      ) {
        storyEvent = "update";
      } else if (offlineCachedStory && latestPublishedStory.archived === true) {
        // this will likely never happen: the API should not return archived stories
        storyEvent = "delete";
      }

      if (storyEvent === null) {
        console.log(
          `[diffPublishedAgainstCache] "${latestPublishedStory.title}" is up to date`
        );

        // next, check for new + updated sequences
        if (latestPublishedStory.sequences.length) {
          latestPublishedStory.sequences?.forEach((latestPublishedSequence) => {
            const cachedSequence = offlineCachedStory?.sequences?.find(
              ({ id }) => id === latestPublishedSequence.id
            );

            if (typeof cachedSequence === "undefined") {
              // add a new sequence
              console.log(
                `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" will be added`
              );
              newSequences.push({
                story: latestPublishedStory,
                sequence: latestPublishedSequence,
              });
            } else if (
              latestPublishedSequence.version > cachedSequence.version
            ) {
              if (selectedSequenceId === cachedSequence.id) {
                console.log(
                  `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" cannot be updated because it is being presented`
                );
                return;
              }
              console.log(
                `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" will be updated`
              );
              updatedSequences.push({
                story: latestPublishedStory,
                sequence: latestPublishedSequence,
              });
            } else if (
              !latestPublishedSequence.isPublishing &&
              cachedSequence.isPublishing
            ) {
              console.log(
                `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" will be updated`
              );
              updatedSequences.push({
                story: latestPublishedStory,
                sequence: latestPublishedSequence,
              });
            }
          });
        }

        // finally, remove any cached sequences which
        offlineCachedStory?.sequences?.forEach((cachedSequence) => {
          const publishedSequence = latestPublishedStory.sequences.find(
            ({ id }) => id === cachedSequence.id
          );

          if (typeof publishedSequence === "undefined") {
            if (selectedSequenceId === cachedSequence.id) {
              console.log(
                `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${cachedSequence.name}" cannot be removed because it is being presented`
              );
              return;
            }
            console.log(
              `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${cachedSequence.name}" will be removed`
            );
            archivedSequences.push({
              story: latestPublishedStory,
              sequence: cachedSequence,
            });
          }
        });
        return;
      }

      console.log(
        `[diffPublishedAgainstCache] Event for "${latestPublishedStory.title}" is "${storyEvent}"`
      );

      if (isMarketingSuiteViewer) {
        // process changes immediately
        switch (storyEvent) {
          case "new":
          case "update":
            newStories.push(latestPublishedStory);
            return;
          case "delete":
            archivedStories.push(latestPublishedStory);
            return;
          default:
            console.log(
              `Could not determine an updateEvent for "${latestPublishedStory.title}"`
            );
        }
      } else {
        const storyWithMediaStatus: Story = {
          ...latestPublishedStory,
          mediaStatus: "incomplete",
        };
        switch (storyEvent) {
          case "new":
            // add immediately, to show in StoriesGrid with media progress UI
            newStories.push(storyWithMediaStatus);
            // add to update queue
            queuedUpdates.push(storyWithMediaStatus);
            return;
          case "update":
            // add to update queue
            queuedUpdates.push(storyWithMediaStatus);
            return;
          case "delete":
            // remove immediately
            archivedStories.push(latestPublishedStory);
            return;
          default:
            console.log(
              `Could not determine an updateEvent for "${latestPublishedStory.title}"`
            );
        }
      }
    });

    if (newStories.length > 0) {
      dispatch(addStories(newStories));
    }

    archivedStories.forEach((story) => {
      dispatch(removeStory(story));
    });

    newSequences.forEach((sequence) => {
      dispatch(addSequence(sequence));
    });

    updatedSequences.forEach((sequence) => {
      dispatch(updateSequence(sequence));
    });

    archivedSequences.forEach((sequence) => {
      dispatch(removeSequence(sequence));
    });

    if (queuedUpdates.length > 0) {
      dispatch(add(queuedUpdates));
    }
  }
);

export const { start, setProgress, remove } =
  storiesOfflineFirstManagerSlice.actions;

export const selectAvailableStoryUpdates = (state: RootState) =>
  state.storiesOfflineFirstManager;

export default storiesOfflineFirstManagerSlice;
