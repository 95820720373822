import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import {
  replaceStructure,
  reset as resetStructure,
  setAccountsWithProjects,
  resetStoryId,
  _goTo,
} from "Store/structure";
import {
  reset as resetSlides,
  setGallery,
  setHotspotIndex,
  setHotspotItemIndex,
  setImageIndex,
  setGallerySlide,
  setShowModal,
  setKeyValue,
  setVideo,
  setNestedVideo,
  toggleHawkeye,
  setPano360ControlsEnabled,
  set3DControlsEnabled,
  setMultiDisplayGallerySlide,
} from "Store/slides";
import { socket as localSocket } from "Store/syncLocal";
import { socket as remoteSocket } from "Store/syncRemote";
import { appFeatures, role } from "config";
import type { AppStartListening } from "Store";

export const syncEmitter = createListenerMiddleware();

const startListeningSyncEmitter =
  syncEmitter.startListening as AppStartListening;

if (role === "broadcast") {
  const hasLocal = appFeatures.includes("sync-local");
  const hasRemote = appFeatures.includes("sync-remote");

  startListeningSyncEmitter({
    matcher: isAnyOf(
      // structure
      replaceStructure,
      resetStructure,
      setAccountsWithProjects,
      resetStoryId,
      _goTo,
      // slides
      resetSlides,
      setGallery,
      setHotspotIndex,
      setHotspotItemIndex,
      setImageIndex,
      setGallerySlide,
      setShowModal,
      setKeyValue,
      setVideo,
      setNestedVideo,
      toggleHawkeye,
      setPano360ControlsEnabled,
      set3DControlsEnabled,
      setMultiDisplayGallerySlide
    ),
    effect: (action, listenerApi) => {
      if (hasLocal && localSocket instanceof Socket) {
        localSocket.emit("action", action);
      }
      if (hasRemote && remoteSocket instanceof Socket) {
        const { nickname, room } = listenerApi.getState().syncRemote;
        remoteSocket.emit("action", {
          ...action,
          meta: { nickname, room, role },
        });
      }
    },
  });
}
