import { isOfflineFirst } from "config";
import type { AuthUser } from "Store/auth";

export const STORAGE_KEY = "showhere-auth-user";

export const getPersistedUser = (): AuthUser | undefined => {
  if (typeof window !== "undefined") {
    const item = window.localStorage.getItem(STORAGE_KEY);
    if (item) return JSON.parse(item);
  }
  return undefined;
};

export const setPersistedUser = (user: AuthUser) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(user));
  }
};

export const removePersistedUser = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(STORAGE_KEY);
  }
};

if (isOfflineFirst === false && typeof window !== "undefined") {
  removePersistedUser();
}
