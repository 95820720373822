import { useEffect } from "react";
import { useAppDispatch } from "Store";
import { getStories } from "Store/stories";
import RemoteSlides from "SlidesRenderer/RemoteSlides";

export default function RemoteJoinPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof process.env.REACT_APP_API_CONTENT_KEY !== "undefined") {
      console.warn(
        "[1/2] Overriding dataProvider from `api:auth` to `api:key` for role `listen`"
      );
      dispatch(getStories());
    } else {
      console.error(
        "TODO implement data fetching for role `listen` when there is no content key to fall back on"
      );
    }
  });

  return <RemoteSlides />;
}
