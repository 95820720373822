import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { setEnabled, setHost, setPort, STORAGE_KEY } from "Store/syncLocal";
import { appFeatures, role } from "config";
import type { AppStartListening } from "Store";

const persistSettings = createListenerMiddleware();

const startListeningPersistSettings =
  persistSettings.startListening as AppStartListening;

if (role === "broadcast" && appFeatures.includes("sync-local")) {
  startListeningPersistSettings({
    matcher: isAnyOf(setEnabled, setHost, setPort),
    effect: (action, listenerApi) => {
      const { enabled, host, port } = listenerApi.getState().syncLocal;
      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ enabled, host, port })
      );
    },
  });
}

export default persistSettings;
