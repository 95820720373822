import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
  type Action,
} from "@reduxjs/toolkit";
import { io, Socket, ManagerOptions } from "socket.io-client";
import { role } from "config";
import { getPersistedState } from "./middleware/local-storage";
import type { AppDispatch, RootState } from "Store";

export const STORAGE_KEY = "showhere-sync-local-settings";

type DefaultState = {
  enabled: boolean;
  host: string;
  port: number;
};

const defaultState: DefaultState = {
  enabled: false,
  host: "0.0.0.0",
  port: 3333,
};

const persistedState = getPersistedState(STORAGE_KEY, defaultState);
const initialState: DefaultState = persistedState ?? defaultState;
// const uniqueClientId = uuid4();
// const waitingForPresenterNotificationId = uuid4();

export let socket: Socket | undefined;

function createSocketConnected(url: string, options?: Partial<ManagerOptions>) {
  let i = 0;
  return new Promise<Socket>((resolve, reject) => {
    const socketInstance = io(url, options);
    const pollConnected = setInterval(() => {
      i++;
      if (i >= 60) {
        clearInterval(pollConnected);
        socketInstance.disconnect();
        reject("Socket connection timed out");
      }
      if (socketInstance?.connected) {
        clearInterval(pollConnected);
        resolve(socketInstance);
      }
    }, 1000);
  });
}

export const connectSocket = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: string }
>("syncLocal/connectSocket", async (params, thunkApi) => {
  const { getState, dispatch, rejectWithValue } = thunkApi;
  const { host, port } = getState().syncLocal;
  socket = await createSocketConnected(
    port === 4333 ? `https://${host}:${port}` : `http://${host}:${port}`
  );
  if (!(socket instanceof Socket)) rejectWithValue("Socket connection failed");

  // const sendHeartbeat = () =>
  //   socket?.emit("heartbeat", { clientId: uniqueClientId, role: role });

  // sendHeartbeat(); // Send initial heartbeat
  // setInterval(sendHeartbeat, 10000); // Send heartbeat every 10 seconds

  if (role === "listen") {
    /* let currentPresenters = 0;
   
    socket.on("connected-presenters", (presenters) => {
      currentPresenters = presenters;
      if(currentPresenters > 0) {
        dispatch(removeMarketingSuiteNotification(waitingForPresenterNotificationId));
      } else {
        dispatch(addMarketingSuiteNotification({
          id: waitingForPresenterNotificationId,
          appearance: "waiting",
        }));
      }
    }) */
    socket.on("action", (action: Action) => {
      if (
        action.type.startsWith("structure/") ||
        action.type.startsWith("slides/")
      ) {
        dispatch(action);
      }
    });
  }
});

export const disconnectSocket = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>("syncLocal/disconnectSocket", async (params, { dispatch }) => {
  if (socket instanceof Socket) {
    socket.io.reconnection(false);
    socket.disconnect();
  }
  socket = undefined;
  dispatch(syncLocalSlice.actions._disconnectSocket());
});

const syncLocalSlice = createSlice({
  name: "syncLocal",
  initialState: { ...initialState, connected: false },
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setHost(state, action: PayloadAction<string>) {
      state.host = action.payload;
    },
    setPort(state, action: PayloadAction<number>) {
      state.port = action.payload;
    },
    _disconnectSocket(state) {
      state.connected = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectSocket.pending, (state) => {
        state.connected = false;
      })
      .addCase(connectSocket.fulfilled, (state) => {
        state.connected = true;
      })
      .addCase(connectSocket.rejected, (state) => {
        state.connected = false;
      });
  },
});

export const { setEnabled, setHost, setPort } = syncLocalSlice.actions;

export const selectSyncLocalEnabled = (state: RootState) =>
  state.syncLocal.enabled;

export default syncLocalSlice;
