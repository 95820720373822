import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  diffLatestPublishedAgainstOfflineCached,
  getPersistedStories,
} from "Store/storiesOfflineFirstManager";
import { setAccountsWithProjects } from "Store/structure";
import { getUser } from "Store/auth/thunks";
import { TransformApiResponse } from "content";
import {
  role,
  dataProvider,
  dataProviderScope,
  isWKWebView,
  isMarketingSuiteViewerElectron,
  isMarketingSuiteViewer,
  isOfflineFirst,
  type DataProvider,
  isElectronPresenter,
} from "config";
import type { RootState, AppDispatch } from "Store";
import type { Slide, MediaItem } from "Store/slides";
import type { AccountsWithProjects } from "Store/structure";
import type { ResponseMessage } from "Store/auth/thunks.types";
import type { GetThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";

type UntransformedSlide = {
  id: number;
  name: string;
  order: number;
  slideData: {
    type: string;
    data: unknown;
  };
};

export type UntransformedPresentation = {
  id: number;
  name: string;
  order: number;
  archived: boolean;
  accountId: number;
  projectId: number;
  contentStatus: ContentStatus;
  latestCompiledPresentationData: {
    version: number;
    updatedAt: Date;
    body: {
      columnGroups: Array<{
        id: number;
        name: string;
        columns: Array<{
          id: number;
          thumbnail?: { fileName: string };
          slides: Array<UntransformedSlide>;
        }>;
      }>;
      settingsData: { bannerMedia: Array<{ fileName: string }> };
    };
    mediaManifest: { mediaItems: Array<MediaItem> };
  };
  sequences: Array<{
    id: number;
    accountId: number;
    projectId: number;
    version: number | null;
    name: string;
    description?: string;
    publishedAt: Date;
    updatedAt: Date;
    slides: Array<UntransformedSlide>;
    contentStatus: ContentStatus;
  }>;
  screenCapManifest: Array<{
    slideId: number;
    screenCapFilename: string;
  }>;
  mediaSize: DownloadSize["media"];
  screencapsSize: DownloadSize["screenCapThumbs"];
};

export type ContentStatus = "draft" | "edited" | "publishing" | "published";

export type Column = {
  id: number;
  thumbnail: string | null;
  slides: Array<Slide>;
};

export type ColumnGroup = {
  id: number;
  columns: Array<Column>;
};

export type DownloadSize = {
  media: number | null;
  screenCapThumbs: number | null;
};

export type Story = {
  id: number;
  order: number;
  accountId: number;
  projectId: number;
  title: string;
  version: number;
  archived: boolean;
  thumbnail: string | null;
  publishedAt: Date;
  columnGroups: Array<ColumnGroup>;
  sequences: Sequences;
  resetRoomControlCommands?: Array<string>;
  mediaStatus?: "incomplete" | "complete";
  mediaCompletedAt?: number;
  typography?: Record<string, unknown>;
  downloadSize: DownloadSize;
};

export type Sequence = {
  id: number;
  accountId: number;
  projectId: number;
  name: string;
  description: string;
  version: number;
  isPublishing: boolean;
  disabled: boolean;
  publishedAt: Date;
  slides: Array<Slide>;
};

export type Stories = Array<Story>;

export type Sequences = Array<Sequence>;

type GetPresentationsAuth = {
  accounts: Array<{
    id: number;
    name: string;
    projects: Array<{
      id: number;
      order: number;
      name: string;
      presentations: Array<UntransformedPresentation>;
    }>;
  }>;
  error?: string;
};
type GetPresentationsNonAuth = Array<UntransformedPresentation>;

type GetStoriesThunkApiConfig = {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: ResponseMessage;
};

export const getStories = createAsyncThunk<
  Stories,
  void,
  GetStoriesThunkApiConfig
>("stories/getStories", async (params, thunkAPI) => {
  try {
    let dataProviderModified = dataProvider;
    if (
      dataProvider === "api:auth" &&
      role === "listen" &&
      typeof process.env.REACT_APP_API_CONTENT_KEY !== "undefined"
    ) {
      console.warn(
        "[2/2] Overriding dataProvider from `api:auth` to `api:key` for role `listen`"
      );
      dataProviderModified = "api:key";
    }

    const stories = await getStoriesData(dataProviderModified, thunkAPI);
    if (!stories) throw Error("No presentations available");

    if (dataProvider.startsWith("api:") && isOfflineFirst === true) {
      thunkAPI.dispatch(diffLatestPublishedAgainstOfflineCached(stories));
    } else {
      thunkAPI.dispatch(setStories(stories));
    }
    return stories;
  } catch (err) {
    const { message } = err as ResponseMessage;
    return thunkAPI.rejectWithValue({ message });
  }
});

type StoriesState = {
  entities: Stories;
  isLoading: boolean;
  firstFetch: {
    loading: boolean;
    error: string | null;
  };
};

const initialState: StoriesState = {
  entities: [],
  isLoading: false,
  firstFetch: {
    loading: true,
    error: null,
  },
};

export const storiesSlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    setStories(state, action: PayloadAction<Stories>) {
      state.firstFetch.loading = false;
      state.firstFetch.error = null;
      state.entities = action.payload;
    },
    setFirstFetchLoading(state, action: PayloadAction<boolean>) {
      state.firstFetch.loading = action.payload;
    },
    addStories(state, action: PayloadAction<Stories>) {
      action.payload.forEach((story) => {
        const existingStory = state.entities.find(({ id }) => id === story.id);
        if (existingStory) {
          const existingStoryIndex = state.entities.findIndex(
            ({ id }) => id === story.id
          );
          state.entities[existingStoryIndex] = story;
        } else {
          state.entities.push(story);
        }
      });
      state.entities.sort((a, b) => a.order - b.order);
    },
    removeStory(state, action: PayloadAction<Story>) {
      const idx = state.entities.findIndex(
        (story) => story.id === action.payload.id
      );
      if (idx !== -1) {
        state.entities.splice(idx, 1);
      }
    },
    addSequence(
      state,
      action: PayloadAction<{ story: Story; sequence: Sequence }>
    ) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      if (state.entities[storyIdx].sequences) {
        state.entities[storyIdx].sequences.push(sequence);
      } else {
        state.entities[storyIdx].sequences = [sequence];
      }
    },
    updateSequence(
      state,
      action: PayloadAction<{ story: Story; sequence: Sequence }>
    ) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      const sequenceIdx = state.entities[storyIdx].sequences.findIndex(
        ({ id }) => id === sequence.id
      );
      if (sequenceIdx === -1) return;
      state.entities[storyIdx].sequences[sequenceIdx] = sequence;
    },
    removeSequence(
      state,
      action: PayloadAction<{ story: Story; sequence: Sequence }>
    ) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      const sequenceIdx = state.entities[storyIdx].sequences.findIndex(
        ({ id }) => id === sequence.id
      );
      if (sequenceIdx === -1) return;
      state.entities[storyIdx].sequences.splice(sequenceIdx, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.firstFetch.loading = false;
        state.firstFetch.error = null;
      })
      .addCase(getStories.rejected, (state, action) => {
        state.isLoading = false;
        state.firstFetch.loading = false;
        state.firstFetch.error = (action.payload as ResponseMessage).message;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.firstFetch.loading = false;
      });
  },
});

export const fetchInitialOfflineState = createAsyncThunk(
  "stories/fetchInitialOfflineState",
  async (params, { dispatch }) => {
    const initialStoriesOffline = await getPersistedStories();
    dispatch(setStories(initialStoriesOffline));
    dispatch(
      setFirstFetchLoading(initialStoriesOffline?.length === 0 ? true : false)
    );
  }
);

const getStoriesData = async (
  dataProvider: DataProvider,
  thunkAPI: GetThunkAPI<GetStoriesThunkApiConfig>
) => {
  if (dataProvider === "disk") {
    const response = await fetch(
      `stories/${process.env.REACT_APP_NAMESPACE}.json`
    );
    const presentations: Stories = await response.json();
    if (presentations.length === 0) throw Error("No presentations available");
    return presentations;
  }

  let request;
  if (dataProvider === "api:key") {
    request = window.fetch(
      `${process.env.REACT_APP_API_URL}sync/non-auth/presentations`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY ?? "",
          "x-content-api-key": process.env.REACT_APP_API_CONTENT_KEY ?? "",
        },
      }
    );
  } else if (dataProvider === "api:auth") {
    if (!dataProviderScope) throw new Error("Missing dataProviderScope"); // TODO rework `NamespaceConfig` to be a union type where (dataProviderScope == `api:key` && dataProviderScope is defined), then this check can be removed
    const {
      accountIds = null,
      projectIds = null,
      presentationIds,
    } = dataProviderScope;

    const headers = new Headers({
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      "x-auth-token-type": "sync",
    });

    const fetchParams: RequestInit = {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        ...(accountIds && { accountIds }),
        ...(projectIds && { projectIds }),
        ...(presentationIds && { presentationIds }),
      }),
    };

    if (isWKWebView) {
      const token = Cookies.get("auth-token");
      if (!token) throw new Error("Missing auth-token");
      headers.set("x-auth-token", token);
    } else {
      fetchParams.credentials = "include";
    }

    request = window.fetch(
      `${process.env.REACT_APP_API_URL}sync/auth/presentations-with-sequences`,
      fetchParams
    );
  } else {
    throw Error(`Unrecognised dataProvider: ${dataProvider}`);
  }
  const response = await request;

  if (response.status === 401) throw Error("Access denied");

  const responseJson: GetPresentationsAuth | GetPresentationsNonAuth =
    await response.json();

  let presentations: Array<UntransformedPresentation> = [];

  if (dataProvider === "api:key") {
    const data = responseJson as GetPresentationsNonAuth;
    presentations = data;
  } else if (dataProvider === "api:auth") {
    const data = responseJson as GetPresentationsAuth;
    const accountsWithProjects: AccountsWithProjects = data.accounts
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((account) => ({
        accountId: account.id,
        name: account.name,
        projects: account.projects
          .filter(({ presentations }) => presentations.length > 0)
          .sort((a, b) => a.order - b.order)
          .map((project) => ({
            id: project.id,
            name: project.name,
            presentations: project.presentations
              .sort((a, b) => a.order - b.order)
              .map((presentation) => presentation.id),
          })),
      }));
    thunkAPI.dispatch(setAccountsWithProjects(accountsWithProjects));

    presentations = data.accounts.flatMap((account) =>
      account.projects
        .flatMap((project) => project.presentations)
        .filter((p) => p.contentStatus !== "draft")
    );
  }

  if (presentations.length === 0) {
    switch (dataProvider) {
      case "api:auth":
        throw Error("No presentations available in authenticated user's scope");
      case "api:key":
        throw Error("No presentations available in content-key scope");
      default:
        throw Error("No presentations available");
    }
  }

  if ("error" in responseJson) throw new Error(responseJson.error);

  if (dataProvider.startsWith("api:")) {
    // determine the base URL for slide media
    let mediaBaseUrlTemplate;
    if (isWKWebView || isElectronPresenter) {
      // 1. Universal file system
      mediaBaseUrlTemplate = `presentations/p-#PRES_ID#/v-#PRES_VERSION#/`;
    } else if (isMarketingSuiteViewer) {
      // 2. MacOS file system
      mediaBaseUrlTemplate = `${process.env.REACT_APP_LOCAL_MEDIA_BASE_URL}presentation-#PRES_ID#/version-#PRES_VERSION#/`;
    } else {
      // 3. Live from AWS S3
      mediaBaseUrlTemplate = `${process.env.REACT_APP_API_MEDIA_BASE_URL}#PRES_ID#/`;
    }

    // determine the base URL for navigation thumbnails
    let screenCapsBaseUrlTemplate;
    if (isWKWebView || isMarketingSuiteViewerElectron) {
      // 1. Universal file system
      screenCapsBaseUrlTemplate = `presentations/p-#PRES_ID#/v-#PRES_VERSION#/thumbs/`;
    } else if (isMarketingSuiteViewer) {
      // 2. MacOS file system
      screenCapsBaseUrlTemplate = `${process.env.REACT_APP_LOCAL_MEDIA_BASE_URL}presentation-#PRES_ID#/version-#PRES_VERSION#/thumbs/`;
    } else {
      // 3. Live from AWS S3
      screenCapsBaseUrlTemplate = `${process.env.REACT_APP_API_MEDIA_BASE_URL}screen_captures/#PRES_ID#/thumbs/`;
    }

    const transformedResponse = TransformApiResponse({
      presentations,
      mediaBaseUrlTemplate,
      screenCapsBaseUrlTemplate,
    });

    return transformedResponse;
  }
};

export const {
  setStories,
  setFirstFetchLoading,
  addStories,
  removeStory,
  addSequence,
  updateSequence,
  removeSequence,
} = storiesSlice.actions;

export const selectStories = (state: RootState) => state.stories.entities;
export const selectFirstFetch = (state: RootState) => state.stories.firstFetch;
export const selectIsLoading = (state: RootState) => state.stories.isLoading;

export default storiesSlice;
