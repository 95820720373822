import { useRef, type PropsWithChildren, type CSSProperties } from "react";
import { syncRemoteConfig } from "config";
import { useAppSelector } from "Store";
import PassiveSlides from "SlidesRenderer/PassiveSlides";
import ScaledSlideContainer from "SlidesRenderer/ScaledSlideContainer";
import Join from "./Join";
import type { HoldScreenTheme, HoldScreenMedia } from "config";
import styles from "./RemoteSlides.module.css";

const DEFAULT_THEME: HoldScreenTheme = {
  color: "white",
  backgroundColor: "#333",
};

function RemoteSlides() {
  const { started, connected, finished, manifest, room } = useAppSelector(
    (state) => state.syncRemote
  );
  const { storyId } = useAppSelector((state) => state.structure);
  const hostIsPresent = manifest.some(({ role }) => role === "broadcast");
  const holdscreen = syncRemoteConfig?.holdscreen ?? { theme: DEFAULT_THEME };

  const containerRef = useRef<HTMLDivElement>(null);

  if (!started && !connected && !finished) {
    return <Join />;
  } else if (started && !connected && !finished) {
    return (
      <Container>
        <Message>
          <Spinner fill={holdscreen?.theme?.color ?? "white"} />
          <p>Connecting...</p>
        </Message>
        <Credit />
      </Container>
    );
  } else if (started && connected && !finished) {
    if (hostIsPresent === false) {
      return (
        <Container
          theme={holdscreen?.theme}
          backgroundMedia={holdscreen?.media}
        >
          <Message alignTop>
            <Spinner fill={holdscreen?.theme?.color} />
            <p>
              Waiting for the presentation host
              <br />
              to join meeting <strong>{room}</strong>...
            </p>
          </Message>
          <Credit />
        </Container>
      );
    } else if (storyId === null) {
      return (
        <Container
          theme={holdscreen?.theme}
          backgroundMedia={holdscreen?.media}
        >
          <Message alignTop>
            <Spinner fill={holdscreen?.theme?.color} />
            <p>
              Waiting for the presentation host
              <br />
              to start presenting in meeting <strong>{room}</strong>...
            </p>
          </Message>
          <Credit />
        </Container>
      );
    } else {
      return (
        <div style={{ height: "100%" }} ref={containerRef}>
          <Container>
            <ScaledSlideContainer containerRef={containerRef}>
              <PassiveSlides />
            </ScaledSlideContainer>
          </Container>
        </div>
      );
    }
  } else if (finished) {
    return (
      <Container>
        <Message>
          <p>Thanks for joining</p>
        </Message>
        <Credit />
      </Container>
    );
  } else {
    return null;
  }
}

function Container({
  children,
  backgroundMedia,
  theme = {
    color: "white",
    backgroundColor: "#333",
  },
}: {
  backgroundMedia?: HoldScreenMedia;
  theme?: HoldScreenTheme | undefined;
} & PropsWithChildren) {
  return (
    <div
      className={styles.container}
      style={
        {
          "--color": theme.color,
          "--background-color": theme.backgroundColor,
        } as CSSProperties
      }
    >
      {backgroundMedia?.src && backgroundMedia?.type === "Video" && (
        <video
          className={styles.holdscreenMedia}
          src={backgroundMedia.src}
          loop
          autoPlay
          muted
        />
      )}
      {backgroundMedia?.src && backgroundMedia?.type === "Image" && (
        <img
          className={styles.holdscreenMedia}
          src={backgroundMedia.src}
          alt=""
        />
      )}
      {children}
    </div>
  );
}

function Message({
  children,
  alignTop = false,
}: { alignTop?: boolean } & PropsWithChildren) {
  return (
    <div
      className={`
        ${styles.status_message} 
        ${alignTop ? styles.status_message__top : ""}
      `}
    >
      {children}
    </div>
  );
}

function Credit() {
  return (
    <div className={styles.credit}>
      <p>
        Powered by{" "}
        <a href="https://showhere.co" target="_blank" rel="noopener noreferrer">
          Showhere
        </a>
      </p>
    </div>
  );
}

function Spinner({ fill }: { fill: string }) {
  return (
    <svg
      className={styles.spinner}
      width={26}
      height={26}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
          id="spinner__gradient"
        >
          <stop stopColor={fill} stopOpacity={0} offset="0%" />
          <stop stopColor={fill} stopOpacity={0.631} offset="63.146%" />
          <stop stopColor={fill} offset="100%" />
        </linearGradient>
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          stroke="url(#spinner__gradient)"
          strokeWidth={2}
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle fill={fill} cx={36} cy={18} r={1}>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  );
}

export default RemoteSlides;
