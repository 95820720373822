import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { HashRouter as Router } from "react-router-dom";
import GitInfo from "react-git-info/macro";
import App from "./App";
import { role, target } from "config";
import { maskEmailAddress } from "utilities";
import "@showhereco/ui/dist/style.css";
import "./index.css";

const gitInfo = GitInfo();

Sentry.init({
  dsn: "https://ec1d655f6bd049fab52d38b02dd06d01@o475832.ingest.sentry.io/5514366",
  environment: process.env.NODE_ENV,
  release: `presenter@${gitInfo.commit.hash}`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    if (event.user?.email) {
      event.user.email = maskEmailAddress(event.user.email);
    }
    return event;
  },
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
});

Sentry.setContext("Environment variables", {
  "Client namespace": process.env.REACT_APP_NAMESPACE,
  "Sync role": role,
  "Sync target": target,
});

window.addEventListener(
  "error",
  (event) => {
    if (!event.target) return;

    const target = event.target as
      | HTMLImageElement
      | HTMLVideoElement
      | HTMLLinkElement;

    if (target instanceof HTMLImageElement) {
      Sentry.captureException(
        new Error(`Failed to load image: ${target.src}`),
        { extra: { url: window.location.href } }
      );
    } else if (target instanceof HTMLVideoElement) {
      Sentry.captureException(
        new Error(`Failed to load video: ${target.src}`),
        { extra: { url: window.location.href } }
      );
    } else if (target instanceof HTMLLinkElement) {
      Sentry.captureException(
        new Error(`Failed to load resource: ${target.href}`),
        { extra: { url: window.location.href } }
      );
    } else {
      Sentry.captureException(new Error("Failed to load other resource"), {
        extra: { url: window.location.href, target },
      });
    }
  },
  true // useCapture - necessary for resource loading errors https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#usecapture
);

window.addEventListener("AppTrackingTransparency", ((ev: CustomEvent) => {
  window.AppTrackingTransparencyAuthorizationStatus =
    ev.detail.AuthorizationStatus;
}) as EventListener);

const root = createRoot(document.getElementById("root") as HTMLDivElement);

root.render(
  <Router>
    <App />
  </Router>
);
