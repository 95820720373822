import isElectron from "is-electron";

type Role = "broadcast" | "listen" | "static";

type Target = "local" | "remote";

type Namespace =
  | "75LondonWall"
  | "105VS"
  | "Aviva"
  | "1WoodCrescent"
  | "8Bishopsgate"
  | "Arlington"
  | "BeaconCapital"
  | "BrentCrossTown"
  | "BritishLand"
  | "CanadaWater"
  | "ChiswickPark"
  | "CushmanWakefield"
  | "dnco"
  | "BrocktonEverlast"
  | "GPE"
  | "HereEast"
  | "JuddStreet"
  | "Landsec"
  | "Lendlease"
  | "Related"
  | "RLAM"
  | "Showhere"
  | "ShowhereSandbox"
  | "ShowhereStaging"
  | "TheAcre"
  | "TheCrownEstate"
  | "TheSmith"
  | "Socius"
  | "WhiteCity"
  | "Colliers"
  | "WhiteCityPlace"
  | "CBRE"
  | "Railpen"
  | "Stanhope"
  | "ThompsonCenter";

type AppFeatures =
  | "sync-local"
  | "sync-remote"
  | "room-control"
  | "pdf-builder";

export type DataProvider = "disk" | "api:key" | "api:auth";

export type DataProviderScope = {
  accountIds: number[];
  projectIds: number[];
  presentationIds: number[];
};

export type HoldScreenMedia = {
  type: "Image" | "Video";
  src: string;
};

export type HoldScreenTheme = {
  color: string;
  backgroundColor: string;
};

type NamespaceConfig = {
  appName: string;
  dataProvider: DataProvider;
  dataProviderScope?: DataProviderScope;
  features: AppFeatures[];
  syncLocal?: {
    holdscreen: {
      media: HoldScreenMedia;
    };
  };
  syncRemote?: {
    apiUrl: string;
    clientUrl: string;
    holdscreen: {
      media?: HoldScreenMedia;
      theme: HoldScreenTheme;
    };
  };
  pdfBuilder?: {
    url: string;
  };
  roomControl?: {
    apiUrl: string;
  };
};

const syncRemoteApiUrl = "https://screenshare-api.showhere.co";

const config: Record<Namespace, NamespaceConfig> = {
  "8Bishopsgate": {
    appName: "8 Bishopsgate",
    dataProvider: "disk",
    features: ["sync-local"],
    roomControl: {
      apiUrl: "http://10.0.0.14:4444",
    },
  },
  "75LondonWall": {
    appName: "75 London Wall",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [],
      projectIds: [54],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/75LondonWall/hold-screen.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-75londonwall.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/75LondonWall/hold-screen.png",
        },
        theme: {
          color: "#a0e9bd",
          backgroundColor: "#294742",
        },
      },
    },
  },
  Arlington: {
    appName: "Advanced Research Clusters",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [7],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Arlington/hold-screen-showhere.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-arc.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Arlington/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "#6d4aff",
        },
      },
    },
  },
  BrentCrossTown: {
    appName: "Brent Cross Town",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [4],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BrentCrossTown/hold-screen-local.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join.brentcrosstown.co.uk",
      holdscreen: {
        media: {
          type: "Video",
          src: "media/BrentCrossTown/hold-screen-remote.mp4",
        },
        theme: {
          color: "white",
          backgroundColor: "black",
        },
      },
    },

    roomControl: {
      apiUrl: "http://192.168.1.99:4444",
    },
  },
  BritishLand: {
    appName: "British Land",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [10],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BritishLand/hold-screen.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-britishland.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BritishLand/hold-screen.png",
        },
        theme: {
          color: "#007da4",
          backgroundColor: "white",
        },
      },
    },
  },
  TheAcre: {
    appName: "The Acre",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [14],
      projectIds: [15],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/TheAcre/hold-screen.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join.theacre.com",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/TheAcre/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "#e7c4ad",
        },
      },
    },
  },
  "1WoodCrescent": {
    appName: "1 Wood Crescent",
    dataProvider: "disk",
    features: ["sync-local"],
  },
  dnco: {
    appName: "DNCO",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [1],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-dnco.showhere.co/",
      holdscreen: {
        theme: {
          color: "white",
          backgroundColor: "black",
        },
      },
    },
  },
  Showhere: {
    appName: "Showhere",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [3],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-demo.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  TheSmith: {
    appName: "The Smith",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [25],
      projectIds: [36],
      presentationIds: [103],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/TheSmith/hold-screen--dark.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-thesmith.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  Railpen: {
    appName: "Railpen",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [43],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-railpen.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/TheSmith/hold-screen--dark.png",
        },
      },
    },
  },
  Socius: {
    appName: "Socius",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [47],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-socius.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  Aviva: {
    appName: "London Cancer Hub",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [39],
      projectIds: [61],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-lch.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  CBRE: {
    appName: "CBRE Presenter",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [27],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-cbre.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ShowhereSandbox: {
    appName: "Showhere Sandbox",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [1, 3],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-sandbox.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ShowhereStaging: {
    appName: "Showhere Staging",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [5],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-staging.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  Stanhope: {
    appName: "Stanhope",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [36],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Stanhope/hold-screen--dark.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-stanhope.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  Colliers: {
    appName: "Colliers",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [35],
      projectIds: [47],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-colliers.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  WhiteCity: {
    appName: "White City",
    dataProvider: "disk",
    features: ["sync-local", "room-control"],
    roomControl: {
      apiUrl: "http://192.168.1.56:4444",
    },
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/WhiteCityPlace/intro/logo-widescreen.png",
        },
      },
    },
  },
  WhiteCityPlace: {
    appName: "White City Place",
    dataProvider: "disk",
    features: [],
  },
  CanadaWater: {
    appName: "Canada Water",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-canadawater.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ChiswickPark: {
    appName: "Chiswick Park",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [24],
      projectIds: [35],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/ChiswickPark/hold-screen.jpg",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-chiswickpark.showhere.co",
      holdscreen: {
        theme: {
          color: "#483C1D",
          backgroundColor: "#F9DA49",
        },
      },
    },
  },
  Related: {
    appName: "Related",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-related.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#143664",
        },
      },
    },
  },
  CushmanWakefield: {
    appName: "Cushman & Wakefield",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [18],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-cushwake.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/CushmanWakefield/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "white",
        },
      },
    },
  },
  HereEast: {
    appName: "Here East",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [31],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-hereeast.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  TheCrownEstate: {
    appName: "The Crown Estate",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-tce.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  JuddStreet: {
    appName: "Judd Street",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-juddstreet.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  BeaconCapital: {
    appName: "Beacon Capital",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [22],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-beaconcapital.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  "105VS": {
    appName: "105VS",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [28],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/105VS/hold-screen-black.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-105vs.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  BrocktonEverlast: {
    appName: "Brockton Everlast",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [33],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-brockton-everlast.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  RLAM: {
    appName: "RLAM",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [34],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-rlam.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  Lendlease: {
    appName: "Lendlease Presenter",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [11],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-lendlease.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  Landsec: {
    appName: "Landsec Discover",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [37],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote", "sync-local"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Landsec/hold-screen.jpg",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-landsec.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  GPE: {
    appName: "GPE",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [9],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Video",
          src: "media/GPE/30-duke-street-st-james.mp4",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-gpe.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  ThompsonCenter: {
    appName: "Thompson Center",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [],
      projectIds: [75],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/ThompsonCenter/hold-screen--dark.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteApiUrl,
      clientUrl: "https://join-tc.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
};

export const target: Target = process.env.REACT_APP_WS_TARGET as Target;
export const role: Role = process.env.REACT_APP_WS_ROLE as Role;
export const nsp: Namespace = process.env.REACT_APP_NAMESPACE as Namespace;
export const contentKey = process.env.REACT_APP_API_CONTENT_KEY;

const nspConfig = config[nsp] ?? {};

if (!nsp && role !== "static") {
  console.warn(
    "Missing environment variable REACT_APP_NAMESPACE",
    process.env.REACT_APP_NAMESPACE
  );
}
if (typeof config[nsp] === "undefined" && role !== "static") {
  console.warn(
    "Missing configuration for namespace",
    process.env.REACT_APP_NAMESPACE
  );
}

export default nspConfig;
export const appFeatures = nspConfig.features;
export const appName = nspConfig.appName;
export const dataProvider = nspConfig.dataProvider;
export const dataProviderScope = nspConfig.dataProviderScope;
export const syncLocalConfig = nspConfig.syncLocal;
export const syncRemoteConfig = nspConfig.syncRemote;
export const roomControlConfig = nspConfig.roomControl;
export const pdfBuilderConfig = nspConfig.pdfBuilder;

export const hasUiControl = role === "broadcast";
export const isMarketingSuiteViewer = target === "local" && role === "listen";
export const isScreenShareViewer = target === "remote" && role === "listen";
export const isWKWebView =
  typeof window !== "undefined"
    ? !!(window as Window).webkit?.messageHandlers &&
      !navigator.userAgent.includes("CriOS")
    : undefined; // exclude Chrome for iOS
export const isWKWebViewTrackingAuthorized = () =>
  (window as Window).AppTrackingTransparencyAuthorizationStatus ===
  "authorized";

export const isElectronPresenter =
  new URLSearchParams(window.location.search).get("mediaContext") ===
  "electronPresenter";

export const isMarketingSuiteViewerElectron =
  typeof window !== "undefined" && isMarketingSuiteViewer
    ? isElectron()
    : undefined;
export const isOfflineFirst =
  isWKWebView ||
  isElectronPresenter ||
  isMarketingSuiteViewerElectron ||
  isMarketingSuiteViewer;
