import { createSlice, nanoid } from "@reduxjs/toolkit";
import { connectSocket as connectSocketLocal } from "./syncLocal";
import { openSocket as connectSocketRemote } from "./syncRemote";
import type { RootState } from "Store";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: NotificationsState = {
  presenterNotifications: [],
  marketingSuiteNotifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addPresenterNotification(state, action) {
      const defaults = {
        id: nanoid(),
        appearance: "info",
        text: "",
      };
      state.presenterNotifications.push({ ...defaults, ...action.payload });
    },
    addMarketingSuiteNotification(
      state,
      action: PayloadAction<MarketingSuiteNotificationProps>
    ) {
      const { appearance } = action.payload;
      const defaults = {
        id: null,
        appearance: "info",
        title: "",
        text: "",
      };
      if (action.payload.id === null) return;
      if (
        state.marketingSuiteNotifications.find(
          ({ id }) => id === action.payload.id
        )
      )
        return;
      if (appearance === "waiting" || appearance === "info") {
        if (
          state.marketingSuiteNotifications.find(
            ({ appearance }) => appearance === "error"
          )
        )
          return;
        state.marketingSuiteNotifications.push({
          ...defaults,
          ...action.payload,
        });
      } else {
        state.marketingSuiteNotifications =
          state.marketingSuiteNotifications.filter(
            ({ appearance }) => appearance !== "waiting"
          );
        state.marketingSuiteNotifications.push({
          ...defaults,
          ...action.payload,
        });
      }
    },
    removePresenterNotification(state, action) {
      const index = state.presenterNotifications.findIndex(
        ({ id }) => id === action.payload
      );
      state.presenterNotifications.splice(index, 1);
    },
    removeMarketingSuiteNotification(state, action) {
      state.marketingSuiteNotifications =
        state.marketingSuiteNotifications.filter(
          ({ id }) => id !== action.payload
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectSocketLocal.rejected, (state, action) => {
        state.presenterNotifications.push({
          id: nanoid(),
          text: "Could not connect to display",
          appearance: "error",
        });
      })
      .addCase(connectSocketRemote.rejected, (state, action) => {
        state.presenterNotifications.push({
          id: nanoid(),
          text: "Could not connect to Screen Share server",
          appearance: "error",
        });
      });
  },
});

export const {
  addPresenterNotification,
  addMarketingSuiteNotification,
  removePresenterNotification,
  removeMarketingSuiteNotification,
} = notificationsSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.notifications.presenterNotifications;
export const selectMarketingSuiteNotifications = (state: RootState) =>
  state.notifications.marketingSuiteNotifications;

export default notificationsSlice;
