import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IconError } from "@showhereco/ui";
import { useAppDispatch, useAppSelector } from "Store";
import {
  removeMarketingSuiteNotification,
  removePresenterNotification,
  selectMarketingSuiteNotifications,
  selectNotifications,
} from "Store/notifications";
import styles from "./Notifications.module.css";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const presenterNotifications = useAppSelector(selectNotifications);
  return (
    <div className={styles.container}>
      <AnimatePresence mode="popLayout">
        {presenterNotifications.map(({ id, text, appearance }) => (
          <motion.div
            layout
            key={id}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ type: "spring", stiffness: 200, damping: 30 }}
            className={`${styles.item} ${styles[`item--${appearance}`]}`}
            onClick={() => dispatch(removePresenterNotification(id))}
          >
            {text}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export const NotificationsForMarketingSuite = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectMarketingSuiteNotifications);
  return (
    <div className={styles.container}>
      <AnimatePresence mode="popLayout">
        {notifications.map(({ id, text, title, appearance }) => (
          <motion.div
            layout
            key={id}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ type: "spring", stiffness: 200, damping: 30 }}
            className={`${styles["item--marketing-suite"]} ${styles[appearance]}`}
            onClick={() => dispatch(removeMarketingSuiteNotification(id))}
          >
            <div className={`${styles["icon"]}`}>
              <MarketingSuiteIcon appearance={appearance} />
            </div>
            <div>
              <p className={`${styles["title"]}`}>{title}</p>
              <p className={`${styles["text"]}`}>{text}</p>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

type MarketingSuiteIconProps = {
  appearance: "info" | "error" | "waiting";
};

const MarketingSuiteIcon = ({ appearance }: MarketingSuiteIconProps) => {
  if (appearance === "error") {
    return <IconError width={"40px"} height={"40px"} color={"#f00"} />;
  } else {
    return <WaitingForPresenter />;
  }
};

function WaitingForPresenter() {
  return (
    <div className={`${styles["waiting-for-presenter"]}`}>
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          opacity=".2"
          d="M16 32a16 16 0 1 1 0-32 16 16 0 0 1 0 32Zm0-28a12 12 0 1 0 0 24 12 12 0 0 0 0-24Z"
          fill="black"
        />
        <path
          d="M32 16h-4A12 12 0 0 0 16 4V0a16 16 0 0 1 16 16Z"
          fill="#13683E"
        />
      </svg>
    </div>
  );
}

export default Notifications;
