import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setAnimated, setDisabled } from "./swipeable";
import { AppDispatch, type RootState } from "Store";
import { role, target, isOfflineFirst } from "config";
import {
  setStories,
  addStories,
  removeStory,
  addSequence,
  updateSequence,
} from "Store/stories";
import { signOut } from "Store/auth/thunks";
import { getPersistedStories } from "Store/storiesOfflineFirstManager";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Stories, Story, Sequence, DownloadSize } from "Store/stories";
import type { SlidePosition } from "Store/slides";

export type ProjectByAccount = {
  id: number;
  name: string;
  presentations: Array<number>;
};

export type AccountWithProjects = {
  accountId: number;
  name: string;
  projects: Array<ProjectByAccount>;
};

export type AccountsWithProjects = Array<AccountWithProjects>;

const STORAGE_KEY = "showhere-accountswithprojects";
const getPersistedAccountsWithProjectsOffline = (): AccountsWithProjects => {
  if (typeof window === "undefined") return [];
  const item = window.localStorage.getItem(STORAGE_KEY);
  if (!item) return [];
  return JSON.parse(item);
};
const setPersistedAccountsWithProjectsOffline = (
  accountsWithProjects: AccountsWithProjects
) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(accountsWithProjects)
    );
  }
};

type SlideState = {
  title: string;
  thumbnail?: string;
  position: SlidePosition;
};

type ColumnState = {
  slideIndex: number;
  slides: Array<SlideState>;
};

export type SequenceState = {
  id: number;
  accountId: number;
  projectId: number;
  name: string;
  slideIndex: number;
  slides: Array<SlideState>;
};

export type StoryState = {
  id: number;
  accountId: number;
  projectId: number;
  title: string;
  thumbnail: string | null;
  columnIndex: number;
  columns: Array<ColumnState>;
  sequences: Array<SequenceState>;
  publishedAt: Date;
  mediaCompletedAt?: number;
  resetRoomControlCommands?: Array<string>;
  downloadSize: DownloadSize;
};

type StoriesState = Array<StoryState>;

type StructureState = {
  contentType: "Presentation" | "Sequence";
  storyId: number | null;
  sequenceId: number | null;
  stories: StoriesState;
  sessionStarted: boolean;
  accountsWithProjects: AccountsWithProjects;
};

const initialState: StructureState = getInitialStructureState([]);

const structureSlice = createSlice({
  name: "structure",
  initialState,
  reducers: {
    replaceStructure(
      state,
      action: PayloadAction<{
        storyId: number;
        sequenceId: number;
        stories: StoriesState;
      }>
    ) {
      state.contentType = "Presentation";
      state.storyId = action.payload.storyId;
      state.sequenceId = action.payload.sequenceId;
      state.stories = action.payload.stories;
    },
    setStructureState(state, action: PayloadAction<StructureState>) {
      return action.payload;
    },
    reset(state) {
      state.contentType = "Presentation";
      state.sessionStarted = false;
      state.storyId = getInitialStoryId(state.stories);
      state.sequenceId = null;
      state.stories.forEach((story) => {
        story.columnIndex = 0;
        story.columns.forEach((column) => {
          column.slideIndex = 0;
        });
        story.sequences?.forEach((sequence) => {
          sequence.slideIndex = 0;
        });
      });
    },
    setAccountsWithProjects(
      state,
      action: PayloadAction<AccountsWithProjects>
    ) {
      state.accountsWithProjects = action.payload;
      if (isOfflineFirst) {
        setPersistedAccountsWithProjectsOffline(action.payload);
      }
    },
    resetStoryId(state) {
      state.storyId = null;
    },
    startSession(state) {
      state.sessionStarted = true;
    },
    endSession(state) {
      state.sessionStarted = false;
    },
    _goTo(state, action: PayloadAction<SlidePosition>) {
      const { s, sequenceId, x, y = null } = action.payload;
      state.contentType = sequenceId ? "Sequence" : "Presentation";
      const story = state.stories.find((story) => story.id === s);
      if (!story) return;
      state.storyId = s;
      if (state.contentType === "Sequence") {
        if (!sequenceId) return;
        state.sequenceId = sequenceId;
        const sequence = story.sequences?.find(({ id }) => id === sequenceId);
        if (sequence) sequence.slideIndex = x;
      } else {
        story.columnIndex = x;
        if (typeof y === "number") story.columns[x].slideIndex = y;
      }
    },
    // useKeyboardArrows: travel through columns, using up/down/left/right (deprecated, pre-sequences)
    goToAdjacent(
      state,
      action: PayloadAction<"up" | "down" | "left" | "right">
    ) {
      if (state.storyId === null) return;
      const story = state.stories.find((story) => story.id === state.storyId);
      if (!story) return;
      const direction = action.payload;
      if (direction === "left") {
        if (story.columnIndex === 0) return;
        story.columnIndex--;
      } else if (direction === "right") {
        if (story.columnIndex === story.columns.length - 1) return;
        story.columnIndex++;
      } else {
        const column = story.columns[story.columnIndex];
        if (direction === "up") {
          if (column.slideIndex === 0) return;
          column.slideIndex--;
        } else if (direction === "down") {
          if (column.slideIndex === column.slides.length - 1) return;
          column.slideIndex++;
        }
      }
    },
    // useKeyboardArrows: cycle through slides regardless of column structure, using left/right
    goToPrevious(
      { storyId, sequenceId, stories },
      action: PayloadAction<void>
    ) {
      if (storyId === null) return;
      const story = stories.find((story) => story.id === storyId);
      if (!story) return;
      if (sequenceId) {
        const sequence = story.sequences?.find(({ id }) => id === sequenceId);
        if (sequence && sequence.slideIndex > 0) sequence.slideIndex--;
      } else {
        const column = story.columns[story.columnIndex];
        if (column.slideIndex > 0) {
          column.slideIndex--;
        } else if (story.columnIndex > 0) {
          const targetColumnIndex = story.columnIndex - 1;
          story.columnIndex = targetColumnIndex;
          story.columns[targetColumnIndex].slideIndex =
            story.columns[targetColumnIndex].slides.length - 1;
        }
      }
    },
    goToNext({ storyId, sequenceId, stories }, action: PayloadAction<void>) {
      if (storyId === null) return;
      const story = stories.find((story) => story.id === storyId);
      if (!story) return;
      if (sequenceId) {
        const sequence = story.sequences?.find(({ id }) => id === sequenceId);
        if (sequence && sequence.slideIndex < sequence.slides.length - 1) {
          sequence.slideIndex++;
        }
      } else {
        const column = story.columns[story.columnIndex];
        if (column.slideIndex < column.slides.length - 1) {
          column.slideIndex++;
        } else if (story.columnIndex < story.columns.length - 1) {
          const targetColumnIndex = story.columnIndex + 1;
          story.columnIndex = targetColumnIndex;
          story.columns[targetColumnIndex].slideIndex = 0;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setStories, (state, action) => {
        const accountsWithProjects = state.accountsWithProjects;
        return {
          ...getInitialStructureState(action.payload),
          accountsWithProjects,
        };
      })
      .addCase(addStories, (state, action) => {
        action.payload.forEach((story) => {
          const newStoryState = getInitialStructureStateForStory(story);
          const idx = state.stories.findIndex(({ id }) => id === story.id);
          if (idx === -1) {
            state.stories.push(newStoryState);
          } else {
            state.stories[idx] = newStoryState;
          }
        });
      })
      .addCase(removeStory, (state, action) => {
        const idx = state.stories.findIndex(
          (story) => story.id === action.payload.id
        );
        if (idx !== -1) {
          state.stories.splice(idx, 1);
        }
      })
      .addCase(addSequence, (state, action) => {
        const { story, sequence } = action.payload;
        const storyIdx = state.stories.findIndex(({ id }) => id === story.id);
        if (storyIdx === -1) return;
        const s = getInitialStructureStateForSequence({
          storyId: story.id,
          accountId: story.accountId,
          projectId: story.projectId,
          sequence,
        });
        if (state.stories[storyIdx].sequences) {
          state.stories[storyIdx].sequences.push(s);
        } else {
          state.stories[storyIdx].sequences = [s];
        }
      })
      .addCase(updateSequence, (state, action) => {
        const { story, sequence } = action.payload;
        const storyIdx = state.stories.findIndex(({ id }) => id === story.id);
        if (storyIdx === -1) return;
        const sequenceIdx = state.stories[storyIdx].sequences.findIndex(
          ({ id }) => id === sequence.id
        );
        if (sequenceIdx === -1) return;
        state.stories[storyIdx].sequences[sequenceIdx] =
          getInitialStructureStateForSequence({
            storyId: story.id,
            accountId: story.accountId,
            projectId: story.projectId,
            sequence,
          });
      })
      .addMatcher(
        (action) => [signOut.fulfilled, signOut.rejected].includes(action.type),
        (state, action) => {
          reset();
        }
      );
  },
});

export const fetchInitialOfflineState = createAsyncThunk(
  "slides/fetchInitialOfflineState",
  async (params, { dispatch }) => {
    const initialStoriesOffline = await getPersistedStories();
    const initialStructureState = getInitialStructureState(
      initialStoriesOffline
    );
    dispatch(setStructureState(initialStructureState));
  }
);

function getInitialStoryId(stories: Pick<Story, "id">[]) {
  let initialStoryId = null;
  if (stories?.length === 1 && target === "local" && role === "listen") {
    initialStoryId = stories[0].id;
  }
  return initialStoryId;
}

function getInitialStructureState(stories: Stories): StructureState {
  return {
    contentType: "Presentation",
    sessionStarted: false,
    storyId: getInitialStoryId(stories),
    sequenceId: null,
    accountsWithProjects: getPersistedAccountsWithProjectsOffline(),
    stories: stories?.flatMap((story) =>
      getInitialStructureStateForStory(story)
    ),
  };
}

function getInitialStructureStateForStory(story: Story) {
  const storyState: StoryState = {
    accountId: story.accountId,
    projectId: story.projectId,
    id: story.id,
    title: story.title,
    thumbnail: story.thumbnail,
    publishedAt: story.publishedAt,
    columnIndex: 0,
    columns: story.columnGroups
      .flatMap(({ columns }) => columns)
      .flatMap((column, columnIndex) => ({
        thumbnail: column.thumbnail,
        slideIndex: 0,
        slides: column.slides.map((slide, y) => ({
          title: slide.title,
          position: {
            s: story.id,
            x: columnIndex,
            y,
          },
        })),
      })),
    sequences: story.sequences?.map((sequence) =>
      getInitialStructureStateForSequence({
        storyId: story.id,
        accountId: story.accountId,
        projectId: story.projectId,
        sequence,
      })
    ),
    resetRoomControlCommands: story.resetRoomControlCommands,
    downloadSize: story.downloadSize,
  };
  if (story.mediaCompletedAt) {
    storyState.mediaCompletedAt = story.mediaCompletedAt;
  }
  return storyState;
}

function getInitialStructureStateForSequence({
  storyId,
  accountId,
  projectId,
  sequence,
}: {
  storyId: Story["id"];
  accountId: Story["accountId"];
  projectId: Story["projectId"];
  sequence: Sequence;
}) {
  const sequenceState: SequenceState = {
    id: sequence.id,
    accountId: accountId,
    projectId: projectId,
    name: sequence.name,
    slideIndex: 0,
    slides: sequence.slides.map((slide, x) => ({
      title: slide.title,
      thumbnail: slide.thumbnail,
      position: {
        s: storyId,
        sequenceId: sequence.id,
        x,
        y: 0,
      },
    })),
  };
  return sequenceState;
}

export const {
  replaceStructure,
  setStructureState,
  reset,
  setAccountsWithProjects,
  resetStoryId,
  goToAdjacent,
  goToPrevious,
  goToNext,
  _goTo,
  startSession,
  endSession,
} = structureSlice.actions;

export const selectSessionStarted = (state: RootState) =>
  state.structure.sessionStarted;
export const selectContentType = (state: RootState) =>
  state.structure.contentType;
export const selectStoryId = (state: RootState) => state.structure.storyId;
export const selectAccountsWithProjects = (state: RootState) =>
  state.structure.accountsWithProjects;
export const selectStories = (state: RootState) => state.structure.stories;
export const selectStory = (state: RootState) => {
  const { storyId, stories } = state.structure;
  if (storyId === null) return null;
  return stories.find((s) => s.id === storyId);
};
export const selectSequenceId = (state: RootState) =>
  state.structure.sequenceId;
export const selectSequence = (state: RootState) => {
  const { contentType, storyId, sequenceId, stories } = state.structure;
  if (contentType !== "Sequence" || storyId === null || sequenceId === null)
    return null;
  const story = stories.find((s) => s.id === storyId);
  if (!story) return;
  return story.sequences.find((s) => s.id === sequenceId);
};

type GoToParams = {
  position: SlidePosition;
  preventAnimatedSlideTransition?: boolean;
};

type GoToThunkApiConfig = { dispatch: AppDispatch; state: RootState };

export const goTo = createAsyncThunk<void, GoToParams, GoToThunkApiConfig>(
  "structure/goTo",
  async (params, thunkAPI) => {
    const { position, preventAnimatedSlideTransition = false } = params;
    if (!position) console.error("_goTo", position);
    const { getState, dispatch } = thunkAPI;

    if (getState().structure.sessionStarted === false) dispatch(startSession());

    if (preventAnimatedSlideTransition === true) {
      dispatch(setAnimated({ x: false, y: false }));
    } else {
      const { s, sequenceId, x, y = null } = position;
      const { storyId, stories } = getState().structure;
      if (storyId !== null) {
        const story = stories.find((story) => story.id === s);
        if (!story) return;
        let col;
        let sli;
        if (sequenceId) {
          const sequence = story.sequences.find(({ id }) => id === sequenceId);
          if (sequence) {
            col = sequence.slideIndex;
            sli = 0;
          }
        } else {
          col = story.columnIndex;
          sli = story.columns[x].slideIndex;
        }

        if (col !== undefined && sli !== undefined && y !== null) {
          const animatedConfig = {
            x: !(x > col + 1 || x < col - 1 || s !== storyId),
            y: !(y > sli + 1 || y < sli - 1 || s !== storyId),
          };
          dispatch(setAnimated(animatedConfig));
        }
      }
    }

    // only reset swipeable-disabled state if the slide is not already selected
    const structureState = getState().structure;
    const storyState = structureState.stories.find(
      ({ id }) => id === position.s
    );
    const slideAlreadySelected =
      structureState.storyId === position.s &&
      storyState &&
      position.x === storyState.columnIndex &&
      storyState.columns[position.x].slideIndex === position.y;
    if (slideAlreadySelected === false) {
      dispatch(setDisabled(false));
    }

    dispatch(_goTo(position));
  }
);

export default structureSlice;
