import React, { useEffect, useState } from "react";

export default function Page() {
  const [ratio, setRatio] = useState(1);
  const [iframeWidth, setIframeWidth] = useState("100%");
  const [iframeHeight, setIframeHeight] = useState("100%");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const aspectRatioParam = urlParams.get("aspectRatio");
    if (aspectRatioParam) {
      const parsedRatio = parseFloat(aspectRatioParam);
      if (!isNaN(parsedRatio)) {
        setRatio(parsedRatio);
      }
    }

    const resizeIframe = () => {
      const iw = window.innerWidth;
      const ih = window.innerHeight;
      const newWidth = `${iw / ih > ratio ? ih * ratio : iw}px`;
      const newHeight = `${iw / ratio > ih ? ih : iw / ratio}px`;
      setIframeWidth(newWidth);
      setIframeHeight(newHeight);
    };

    resizeIframe();
    window.addEventListener("resize", resizeIframe);

    return () => {
      window.removeEventListener("resize", resizeIframe);
    };
  }, [ratio]);

  return (
    <div
      style={{
        margin: 0,
        display: "flex",
        background: "black",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <iframe
        title="Showhere"
        id="iframe"
        src="/"
        style={{ border: 0, width: iframeWidth, height: iframeHeight }}
      ></iframe>
    </div>
  );
}
