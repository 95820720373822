import { useState, useRef, useEffect, type FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { useAppDispatch } from "Store";
import {
  setIsLoading,
  selectIsLoading,
  selectError,
  selectUserIsLoggedIn,
} from "Store/auth";
import { signIn } from "Store/auth/thunks";
import { appName } from "config";
import openURL from "helpers/openURL";
import { useOnlineStatus } from "useOnlineStatus";
import styles from "./SignInForm.module.css";
import { Button, Input } from "@showhereco/ui";

const lastLoginEmailLocalStorageKey = "showhere-login-last-email";

type ErrorMessage = {
  message: "maxFailedLoginAttempts" | "failedCaptcha" | "offline";
};

type ExpiredErrorMessage = {
  message: "expired";
  passwordDuration: number;
  token: string;
};

export default function SignInForm() {
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [didSubmit, setDidSubmit] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const dispatch = useAppDispatch();

  const lastLoginEmail =
    localStorage.getItem(lastLoginEmailLocalStorageKey) ?? "";

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    setDidSubmit(true);
    dispatch(setIsLoading(true));
    const credentials = {
      email: emailRef.current?.value ?? "",
      password: passwordRef.current?.value ?? "",
    };
    dispatch(signIn(credentials));
    localStorage.setItem(lastLoginEmailLocalStorageKey, credentials.email);
  };

  const renderSubmitError = () => {
    if (!didSubmit || !error) return null;
    return error.message === "expired" ? (
      <Error {...(error as ExpiredErrorMessage)} />
    ) : (
      <Error {...(error as ErrorMessage)} />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <p>{appName}</p>
      </div>
      <div className={styles.formContainer}>
        <form className={styles.form} method="post" onSubmit={handleSubmit}>
          <legend>Sign in to your account</legend>
          <label htmlFor="email-input">Email address</label>
          <Input
            type="email"
            name="email"
            data-cy="emailInput"
            ref={emailRef}
            defaultValue={lastLoginEmail}
            required
          />

          <label htmlFor="password-input">Password</label>

          <div className={styles.passwordField}>
            <Input
              type="password"
              name="password"
              autoComplete="current-password"
              ref={passwordRef}
              data-cy="passwordInput"
              required
            />
          </div>

          <div className={styles.forgot}>
            <a
              href="https://dashboard.showhere.co/auth/password-reset"
              onClick={(ev) => {
                ev.preventDefault();
                openURL((ev.target as HTMLAnchorElement).href);
              }}
            >
              Forgot your password?
            </a>
          </div>

          <AnimatePresence>{renderSubmitError()}</AnimatePresence>

          {isOnline ? (
            <Button
              label={isLoading ? "Signing in..." : "Sign in"}
              type="submit"
              variant="primary"
              size="xl"
              align="center"
              disabled={!isOnline || isLoading}
            >
              {isLoading ? "Signing in..." : "Sign in"}
            </Button>
          ) : (
            <Error message="offline" />
          )}
        </form>
      </div>
    </div>
  );
}

const Error = (props: ErrorMessage | ExpiredErrorMessage) => {
  const loginErrorResponse = () => {
    const { message } = props;
    if (message === "maxFailedLoginAttempts" || message === "failedCaptcha") {
      return (
        <p className={styles.error__text}>
          Too many failed login attempts. Please{" "}
          <a
            className={styles.error__link}
            href="https://dashboard.showhere.co/auth/login"
            onClick={(ev) => {
              ev.preventDefault();
              openURL((ev.target as HTMLAnchorElement).href);
            }}
          >
            click here
          </a>{" "}
          to login.
        </p>
      );
    } else if (message === "expired") {
      const { passwordDuration, token } = props as ExpiredErrorMessage;
      return (
        <p className={styles.error__text}>
          <b>Your password has expired</b>
          <br />
          Your account security requires that you change your password.
          <br />
          <br /> Please{" "}
          <a
            className={styles.error__link}
            href={`https://dashboard.showhere.co/auth/password-expired?passwordDuration=${passwordDuration}&token=${token}`}
            onClick={(ev) => {
              ev.preventDefault();
              openURL((ev.target as HTMLAnchorElement).href);
            }}
          >
            click here
          </a>{" "}
          to update your password.
        </p>
      );
    } else if (message === "offline") {
      return <p className={styles.error__text}>You are currently offline.</p>;
    } else {
      return (
        <p className={styles.error__text}>
          We couldn't log you in with these details. Please check your email
          address and password.
        </p>
      );
    }
  };
  return (
    <motion.div
      key="text"
      className={styles.error}
      initial={{ scale: 0.97, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.97, opacity: 0 }}
      transition={{ type: "spring" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={styles.error__icon}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>

      {loginErrorResponse()}
    </motion.div>
  );
};
