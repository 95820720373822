import { type PropsWithChildren } from "react";
import { AnalyticsListener, AnalyticsManager } from "@showhereco/ui";
import * as Sentry from "@sentry/react";
import { Provider, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import store from "Store";
import {
  analyticsModel,
  showhereApi,
  type AnalyticsEvent,
  type EnrichedAnalyticsEvent,
} from "Analytics";
import { selectUserIsLoggedIn } from "Store/auth";
import { selectFirstFetch } from "Store/stories";
import { OnlineStatusProvider } from "useOnlineStatus";
import Presenter from "pages/presenter";
import SignInForm from "SignInForm";
import SuiteView from "pages/suite-view";
import ConstrainAspectRatio from "pages/constrain-aspect-ratio";
import RemoteJoin from "pages/remote-join";
import ScreenshotSlide from "pages/screenshot-slide";
import { dataProvider } from "config";

//Update all actions commit : Remove in future

// create Analytics Manager and connected suites
//@ts-ignore - remove in turborepo
const analyticsManager = AnalyticsManager<EnrichedAnalyticsEvent>([
  showhereApi,
]);

// create Analytics Listener and re-export the hook at the bottom of this file
//@ts-ignore - remove in turborepo
const { AnalyticsProvider, useAnalytics } = AnalyticsListener<
  AnalyticsEvent,
  EnrichedAnalyticsEvent
>({
  analyticsModel,
  analyticsManager,
});
export { useAnalytics };

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <AnalyticsProvider>
        <OnlineStatusProvider>
          <Provider store={store}>
            <Routes>
              <Route path="/auth/sign-in" element={<SignInForm />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Presenter />
                  </RequireAuth>
                }
              />
              <Route
                path="/constrain-aspect-ratio"
                element={<ConstrainAspectRatio />}
              />
              <Route path="/remote-join" element={<RemoteJoin />} />
              <Route path="/screenshot-slide" element={<ScreenshotSlide />} />
              <Route path="/suite-view" element={<SuiteView />} />
              <Route
                path="*"
                element={
                  <RequireAuth>
                    <Presenter />
                  </RequireAuth>
                }
              />
            </Routes>
          </Provider>
        </OnlineStatusProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
}

function RequireAuth({ children }: PropsWithChildren) {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const { loading } = useSelector(selectFirstFetch);

  if (
    dataProvider === "api:auth" &&
    userIsLoggedIn === false &&
    loading === false
  )
    return <Navigate to="/auth/sign-in" replace />;

  return <>{children}</>;
}

function ErrorFallback() {
  return (
    <div className="error-fallback" onClick={() => window.location.reload()}>
      <div className="error-fallback__body">
        <h1>An error has occurred</h1>
        <p>Tap anywhere to reload the presentation</p>
      </div>
      <div className="error-fallback__footer">
        <p>Technical support has been notified</p>
      </div>
    </div>
  );
}
