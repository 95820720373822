import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { syncLocalConfig } from "config";
import { selectSlide } from "Store/slides";
import Slide from "SlidesRenderer/Slide";

const PassiveSlides = () => {
  const slide = useSelector(selectSlide);
  const holdscreen = syncLocalConfig?.holdscreen;

  const hasSlideData = slide?.slideId;
  const hasHoldscreen = holdscreen?.media?.src;

  // no slide and holdscreen - show holdscreen
  if (!hasSlideData && hasHoldscreen)
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          backgroundImage: `url(${holdscreen.media.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    );

  // no slide, no holdscreen - show error
  if (!hasSlideData)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p>
          We're sorry. There appears to be an issue with this presentation, our
          team has been notified, please try again later.
        </p>
      </div>
    );

  // render slide
  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={`slide-${slide?.position?.s}-${slide?.position?.x}-${slide?.position?.y}`}
        style={{ position: "absolute", width: "100%", height: "100%" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Slide {...slide} isCurrent={true} />
      </motion.div>
    </AnimatePresence>
  );
};

export default PassiveSlides;
