import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { isOfflineFirst } from "config";
import { getStories } from "Store/stories";
import { signIn, getUser, signOut } from "./thunks";
import {
  getPersistedUser,
  setPersistedUser,
  removePersistedUser,
} from "./persistUser";
import { endSession as endAnalyticsSession } from "Analytics";
import type { RootState } from "Store";
import type { SignInError, ResponseMessage } from "./thunks.types";

export type AuthUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  presentationIds: number[];
  showhereRole?: number;
  accounts?: Array<{ id: number; name: string }>;
};

type AuthState = {
  user: AuthUser | undefined;
  isLoading: boolean;
  error?: SignInError | ResponseMessage;
  offlineSessionExpired: boolean;
};

const initialState: AuthState = {
  user: getPersistedUser(),
  isLoading: false,
  error: undefined,
  offlineSessionExpired: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUser(state, action) {
      state.user = {
        ...action.payload,
        presentationIds: state.user?.presentationIds ?? [],
      };
      if (state.user) setPersistedUser(state.user);
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUserPresentationIds(state, action) {
      if (state.user) {
        state.user.presentationIds = action.payload;
        setPersistedUser(state.user);
      }
    },
    setOfflineSessionExpired(state, action) {
      state.offlineSessionExpired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        if (isOfflineFirst === false) {
          state.isLoading = false;
        }
        state.user = {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          presentationIds: state.user?.presentationIds ?? [],
          id: action.payload.id,
        };
        state.offlineSessionExpired = false;

        Sentry.setUser({
          id: action.payload.id,
          email: action.payload.email,
        });

        if (window.pendo) {
          const role = action.payload.showhereRole ?? null;
          const accountId =
            role === 1 ? "SuperUsers" : `${action.payload.accounts?.[0]?.name}`;
          window.pendo.initialize({
            visitor: { id: `${action.payload.id}`, role },
            account: { id: accountId },
          });
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        const { payload } = action as PayloadAction<ResponseMessage>;

        if (isOfflineFirst === false) {
          state.isLoading = false;
        }
        state.error = payload;

        // When the cookie has expired, no auth-token is sent
        if (payload.message === "No auth-token provided") {
          state.offlineSessionExpired = true;
        }
      })
      .addCase(signIn.pending, (state, action) => {
        state.error = initialState.error;
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = initialState.error;
        state.offlineSessionExpired = false;
        const user = {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          id: action.payload.id,
          presentationIds: [],
        };
        state.user = user;
        setPersistedUser(user);

        Sentry.setUser({
          id: action.payload.id,
          email: action.payload.email,
        });
      })
      .addCase(signIn.rejected, (state, action) => {
        const { payload } = action as PayloadAction<SignInError>;
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(signOut.fulfilled, (state) => {
        handleSignOut(state);
        state.error = initialState.error;
      })
      .addCase(signOut.rejected, (state, action) => {
        const { payload } = action as PayloadAction<ResponseMessage>;
        handleSignOut(state);
        state.error = payload;
      })
      .addCase(getStories.fulfilled, (state, action) => {
        const ids = action.payload.map(({ id }: { id: number }) => id);
        if (state.user) {
          state.user.presentationIds = ids;
          setPersistedUser(state.user);
        }
      })
      .addCase(getStories.rejected, (state, action) => {
        const { payload } = action as PayloadAction<ResponseMessage>;
        // When the cookie has expired, no auth-token is sent
        if (payload.message === "Missing auth-token") {
          state.offlineSessionExpired = true;
        }
      });
  },
});

function handleSignOut(state: AuthState) {
  state.user = undefined;
  state.isLoading = initialState.isLoading;

  removePersistedUser();
  endAnalyticsSession();
  Sentry.setUser(null);
}

export const { setIsLoading, setUser, setError, setUserPresentationIds } =
  authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserIsLoggedIn = (state: RootState) => !!state.auth.user;
export const selectError = (state: RootState) => state.auth.error;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectUserPresentationIds = (state: RootState) =>
  state.auth.user?.presentationIds ?? [];
export const selectUserOfflineSessionExpired = (state: RootState) =>
  state.auth.offlineSessionExpired;

export default authSlice;
