import {
  configureStore,
  combineReducers,
  type ListenerEffectAPI,
  type TypedStartListening,
} from "@reduxjs/toolkit";
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook,
} from "react-redux";
import * as Sentry from "@sentry/react";
import authSlice from "./auth";
import navigationSlice from "./navigation";
import notificationsSlice from "./notifications";
import storiesSlice from "./stories";
import storiesOfflineFirstManagerSlice from "./storiesOfflineFirstManager";
import structureSlice from "./structure";
import slidesSlice from "./slides";
import swipeableSlice from "./swipeable";
import syncLocalSlice from "./syncLocal";
import persistSyncLocalSettings from "./syncLocalPersistListener";
import syncRemoteSlice from "./syncRemote";
import { syncEmitter } from "./middleware/sync-emitter";
import { persistStories } from "Store/stories/persistStoriesListener";
import { logger } from "Store/loggerListener";

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [storiesSlice.name]: storiesSlice.reducer,
  [storiesOfflineFirstManagerSlice.name]:
    storiesOfflineFirstManagerSlice.reducer,
  [structureSlice.name]: structureSlice.reducer,
  [slidesSlice.name]: slidesSlice.reducer,
  [swipeableSlice.name]: swipeableSlice.reducer,
  [syncLocalSlice.name]: syncLocalSlice.reducer,
  [syncRemoteSlice.name]: syncRemoteSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [navigationSlice.name]: navigationSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      logger.middleware,
      persistStories.middleware,
      persistSyncLocalSettings.middleware,
      syncEmitter.middleware
    ),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers({ autoBatch: { type: "tick" } }).concat(
      Sentry.createReduxEnhancer()
    ),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
