import { isWKWebView, isMarketingSuiteViewerElectron } from "config";

export default function openURL(url: string) {
  try {
    if (isWKWebView) {
      window.webkit.messageHandlers.actionWithPayload.postMessage({
        action: "openURL",
        payload: { url },
      });
    } else if (isMarketingSuiteViewerElectron) {
      window.electronAPI?.openURL(url);
    } else {
      window.open(url, "_blank");
    }
  } catch (err) {
    console.log(err);
  }
}
