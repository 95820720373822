import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { signOut } from "./auth/thunks";

type SwipeableState = {
  animated: { x: boolean; y: boolean };
  disabled: boolean;
};

const initialState: SwipeableState = {
  animated: { x: true, y: true },
  disabled: false,
};

const swipeableSlice = createSlice({
  name: "swipeable",
  initialState,
  reducers: {
    setAnimated(state, action: PayloadAction<{ x: boolean; y: boolean }>) {
      state.animated = action.payload;
    },
    setDisabled(state, action: PayloadAction<boolean>) {
      state.disabled = action.payload;
    },
    reset(state) {
      state.animated = initialState.animated;
      state.disabled = initialState.disabled;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => [signOut.fulfilled, signOut.rejected].includes(action.type),
      (state, action) => {
        reset();
      }
    );
  },
});

export const { setAnimated, setDisabled, reset } = swipeableSlice.actions;

export default swipeableSlice;
