import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { AppStartListening } from "Store";

export const logger = createListenerMiddleware();

const startListeningLogger = logger.startListening as AppStartListening;

if (process.env.REACT_APP_ENABLE_LOGGING) {
  startListeningLogger({
    predicate: () => true,
    effect: (action, listenerApi) => {
      const now = new Date();
      const timeString =
        now.toLocaleTimeString([], { hour12: false }) +
        "." +
        now.getMilliseconds();

      console.groupCollapsed(
        `%c action %c${action.type} %c@ ${timeString}`,
        `color: gray; font-weight: lighter;`,
        `font-weight: bold`,
        `color: gray; font-weight: lighter;`
      );

      // prev state
      console.log(
        "%cprev state ",
        `color: #9E9E9E; font-weight: bold`,
        listenerApi.getState()
      );

      // action
      console.log("%caction     ", `color: #03A9F4; font-weight: bold`, action);

      // next state
      // console.log(
      //   "%cnext state ",
      //   `color: #4CAF50; font-weight: bold`,
      //   "not yet implemented"
      // );

      console.groupEnd();
    },
  });
}
