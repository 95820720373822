import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { signOut } from "./auth/thunks";
import type { RootState } from "Store";

type NavigationState = {
  lastSelectedTabs: Record<number, string>;
};

const initialState: NavigationState = {
  lastSelectedTabs: {},
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setLastSelectedTab(
      state,
      action: PayloadAction<{ storyId: number; tabName: string }>
    ) {
      const { storyId, tabName } = action.payload;
      state.lastSelectedTabs[storyId] = tabName;
    },
    clearLastSelectedTabs(state) {
      state.lastSelectedTabs = initialState.lastSelectedTabs;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => [signOut.fulfilled, signOut.rejected].includes(action.type),
      (state, action) => {
        clearLastSelectedTabs();
      }
    );
  },
});

export const { setLastSelectedTab, clearLastSelectedTabs } =
  navigationSlice.actions;

export const selectLastSelectedTabs = (state: RootState) =>
  state.navigation.lastSelectedTabs;

export default navigationSlice;
