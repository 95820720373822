import store from "Store";
import {
  selectContentType,
  selectSequenceId,
  selectStory,
  selectStoryId,
} from "Store/structure";

type sources = "presentation" | "sequence";

type eventNames = "enter" | "exit";

// define the session
export type AnalyticsSession = {
  accountId: number;
  deviceType: string;
  duration?: number;
  ended?: Date;
  events?: EnrichedAnalyticsEvent[];
  projectId: number;
  sessionId?: number;
  sessionTypeId: number;
  started?: Date;
  targetUserId?: number;
  userId: number;
};

// define the basic event
// this could eventually be many different types collated into one
export type AnalyticsEvent = {
  event: eventNames;
  slideId: number;
  slideType: sources;
  slideTypeId: number;
};

// define the enriched event
// in this case we're just adding a user property
export type EnrichedAnalyticsEvent = {
  accountId: number;
  action?: string;
  category?: string;
  duration?: number;
  ended?: Date;
  label?: string;
  presentationId: number;
  projectId: number;
  sequenceId?: number;
  sessionId: number;
  sessionTypeId: number;
  slideId: number;
  started: Date;
  value?: number;
};

// define the custom function to model the event
export const analyticsModel = (hit: AnalyticsEvent): EnrichedAnalyticsEvent => {
  const state = store.getState();
  const story = selectStory(state);
  const contentType = selectContentType(state).toLowerCase();
  const sourceId =
    contentType === "presentation"
      ? selectStoryId(state) ?? undefined
      : selectSequenceId(state) ?? undefined;

  const enrichedHit: EnrichedAnalyticsEvent = {
    started: new Date(),
    accountId: story?.accountId ?? -1,
    projectId: story?.projectId ?? -1,
    slideId: hit.slideId,
    presentationId: story?.id ?? -1,
    sequenceId: contentType === "sequence" ? sourceId : undefined,
    sessionId: -1,
    sessionTypeId: -1,
  };

  switch (hit.event) {
    case "enter":
      return {
        ...enrichedHit,
        category: "slide",
        action: "view",
      };
    case "exit":
      return {
        ...enrichedHit,
        category: "slide",
        action: "viewed",
        ended: new Date(),
      };
    default:
      return enrichedHit;
  }
};
