import { useEffect } from "react";
import { useAppDispatch } from "Store";
import { getStories } from "Store/stories";
import PassiveSlides from "SlidesRenderer/PassiveSlides";
import { useSyncLocal } from "useSyncLocal";
import {
  addMarketingSuiteNotification,
  removeMarketingSuiteNotification,
} from "Store/notifications";
import { NotificationsForMarketingSuite } from "Notifications";
import { isMarketingSuiteViewerElectron } from "config";

export default function SuiteViewPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMarketingSuiteViewerElectron) {
      window.electronAPI?.onNotification((notification) => {
        if (notification.type === "dismiss") {
          dispatch(removeMarketingSuiteNotification(notification.id));
          return;
        }
        dispatch(addMarketingSuiteNotification(notification));
      });
    }
    if (typeof process.env.REACT_APP_API_CONTENT_KEY !== "undefined") {
      console.warn(
        "[1/2] Overriding dataProvider from `api:auth` to `api:key` for role `listen`"
      );
      dispatch(getStories());
    } else {
      console.error(
        "TODO implement data fetching for role `listen` when there is no content key to fall back on"
      );
    }
  });

  useSyncLocal();
  useHideMouse(true);

  return (
    <>
      <NotificationsForMarketingSuite />
      <PassiveSlides />
    </>
  );
}

function useHideMouse(enabled: boolean) {
  useEffect(() => {
    if (enabled) {
      let mouseTimer: number | null = null;
      let cursorVisible = true;
      function disappearCursor() {
        mouseTimer = null;
        cursorVisible = false;
        document.body.classList.add("hide-cursor");
      }
      document.onmousemove = function () {
        if (mouseTimer) {
          window.clearTimeout(mouseTimer);
        }
        if (!cursorVisible) {
          document.body.classList.remove("hide-cursor");
          cursorVisible = true;
        }
        mouseTimer = window.setTimeout(disappearCursor, 2000);
      };
    }
  });
  return null;
}
