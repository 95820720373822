import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSyncLocalEnabled, connectSocket } from "Store/syncLocal";
import { useAppDispatch } from "Store";

export const useSyncLocal = () => {
  const dispatch = useAppDispatch();

  const enabled = useSelector(selectSyncLocalEnabled);
  useEffect(() => {
    if (enabled) dispatch(connectSocket());
  }, [enabled, dispatch]);

  return null;
};
