import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  setStories,
  addStories,
  removeStory,
  addSequence,
  updateSequence,
  removeSequence,
} from "Store/stories";
import {
  getPersistedStories,
  setPersistedStories,
} from "Store/storiesOfflineFirstManager";
import { isOfflineFirst } from "config";
import { findIndexById } from "Store/helpers";
import type { AppStartListening } from "Store";

export const persistStories = createListenerMiddleware();

const startListeningPersistStories =
  persistStories.startListening as AppStartListening;

if (isOfflineFirst) {
  startListeningPersistStories({
    actionCreator: setStories,
    effect: (action, listenerApi) => {
      setPersistedStories(action.payload);
    },
  });

  startListeningPersistStories({
    actionCreator: addStories,
    effect: async (action, listenerApi) => {
      let stories = await getPersistedStories();
      action.payload.forEach((story) => {
        const storyIdx = findIndexById(stories, story.id);
        if (storyIdx === -1) {
          stories.push(story);
        } else {
          stories[storyIdx] = story;
        }
      });
      setPersistedStories(stories);
    },
  });

  startListeningPersistStories({
    actionCreator: removeStory,
    effect: async (action, listenerApi) => {
      let stories = await getPersistedStories();
      const storyIdx = findIndexById(stories, action.payload.id);
      if (storyIdx === -1) console.error("No such story");
      stories.splice(storyIdx, 1);
      setPersistedStories(stories);
    },
  });

  startListeningPersistStories({
    actionCreator: addSequence,
    effect: async (action, listenerApi) => {
      let stories = await getPersistedStories();
      const storyIdx = findIndexById(stories, action.payload.story.id);
      if (storyIdx === -1) console.error("No such story");
      if (Array.isArray(stories[storyIdx].sequences)) {
        stories[storyIdx].sequences.push(action.payload.sequence);
      } else {
        stories[storyIdx].sequences = [action.payload.sequence];
      }
      setPersistedStories(stories);
    },
  });

  startListeningPersistStories({
    actionCreator: updateSequence,
    effect: async (action, listenerApi) => {
      let stories = await getPersistedStories();
      const storyIdx = findIndexById(stories, action.payload.story.id);
      if (storyIdx === -1) console.error("No such story");
      const sequenceIdx = findIndexById(
        stories[storyIdx].sequences,
        action.payload.sequence.id
      );
      if (sequenceIdx === -1) console.error("No such sequence");
      stories[storyIdx].sequences[sequenceIdx] = action.payload.sequence;
      setPersistedStories(stories);
    },
  });

  startListeningPersistStories({
    actionCreator: removeSequence,
    effect: async (action, listenerApi) => {
      let stories = await getPersistedStories();
      const storyIdx = findIndexById(stories, action.payload.story.id);
      if (storyIdx === -1) console.error("No such story");
      const sequenceIdx = findIndexById(
        stories[storyIdx].sequences,
        action.payload.sequence.id
      );
      if (sequenceIdx === -1) console.error("No such sequence");
      stories[storyIdx].sequences.splice(sequenceIdx, 1);
      setPersistedStories(stories);
    },
  });
}
