import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { isWKWebView } from "config";
import { getStories } from "Store/stories";
import { setUserPresentationIds, type AuthUser } from "Store/auth";
import type { AppDispatch, RootState } from "Store";
import type {
  ResponseMessage,
  SignInParams,
  SignInError,
  SignInProps,
  SignInEventResponse,
  GetUserProps,
  GetUserEventResponse,
  SignOutProps,
  SignOutEventResponse,
} from "Store/auth/thunks.types";

const getAuthPlatform = () => (isWKWebView ? "iOS" : "web");

export const signIn = createAsyncThunk<
  AuthUser,
  SignInParams,
  { dispatch: AppDispatch; state: RootState; rejectValue: SignInError }
>("auth/signIn", (params, thunkAPI) => {
  thunkAPI.dispatch(setUserPresentationIds([]));
  const fetchUrl = `${process.env.REACT_APP_API_URL}auth/login`;
  const payload = { params, thunkAPI, fetchUrl };
  return handleSignIn[getAuthPlatform()](payload);
});

export const getUser = createAsyncThunk<
  AuthUser,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: ResponseMessage }
>("auth/getUser", (params, thunkAPI) => {
  const fetchUrl = `${process.env.REACT_APP_API_URL}user/me`;
  const payload = { thunkAPI, fetchUrl };
  return handleGetUser[getAuthPlatform()](payload);
});

export const signOut = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: ResponseMessage }
>("auth/signOut", (params, thunkAPI) => {
  const fetchUrl = `${process.env.REACT_APP_API_URL}auth/logout`;
  const payload = { thunkAPI, fetchUrl };
  return handleSignOut[getAuthPlatform()](payload);
});

const handleSignIn = {
  iOS: async ({ params, thunkAPI, fetchUrl }: SignInProps) => {
    try {
      const response = await new Promise<{ user: AuthUser }>(
        (resolve, reject) => {
          window.addEventListener("handleAuth.signIn", ((
            event: CustomEvent<SignInEventResponse>
          ) => {
            if (event.detail.status === "ok") {
              resolve({ user: event.detail.user });
            } else {
              reject({
                message: event.detail.message,
                passwordDuration: event.detail.passwordDuration,
                attempts: event.detail.attempts,
              });
            }
          }) as EventListener);
          window.webkit?.messageHandlers.handleAuth.postMessage({
            action: "signIn",
            payload: {
              endpoint: fetchUrl,
              email: params.email,
              password: params.password,
            },
          });
        }
      );
      thunkAPI.dispatch(getStories());
      return response.user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err as SignInError);
    }
  },
  web: async ({ params, thunkAPI, fetchUrl }: SignInProps) => {
    try {
      const response = await window.fetch(fetchUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params),
      });
      if (response.status === 401) {
        const loginError = await response.json();
        throw loginError;
      }
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      thunkAPI.dispatch(getStories());
      const user = await response.json();
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err as SignInError);
    }
  },
};

const handleGetUser = {
  iOS: async ({ thunkAPI, fetchUrl }: GetUserProps) => {
    try {
      const response = await new Promise<{ user: AuthUser }>(
        (resolve, reject) => {
          window.addEventListener("handleAuth.getUser", ((
            event: CustomEvent<GetUserEventResponse>
          ) => {
            event.detail.status === "ok"
              ? resolve({ user: event.detail.user })
              : reject({ message: event.detail.message });
          }) as EventListener);
          window.webkit.messageHandlers.handleAuth.postMessage({
            action: "getUser",
            payload: {
              endpoint: fetchUrl,
              authToken: Cookies.get("auth-token"),
            },
          });
        }
      );
      thunkAPI.dispatch(getStories());
      return response.user;
    } catch (err) {
      const { message } = err as ResponseMessage;
      return thunkAPI.rejectWithValue({ message });
    }
  },
  web: async ({ thunkAPI, fetchUrl }: GetUserProps) => {
    try {
      const response = await window.fetch(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
      });
      if (!response.ok) {
        const { message = "getUser failed" } = await response.json();
        throw new Error(message);
      }
      const user = await response.json();
      thunkAPI.dispatch(getStories());
      return user;
    } catch (err) {
      const { message } = err as ResponseMessage;
      return thunkAPI.rejectWithValue({ message });
    }
  },
};

const handleSignOut = {
  iOS: async ({ thunkAPI, fetchUrl }: SignOutProps) => {
    try {
      await new Promise<void>((resolve, reject) => {
        if (window.navigator.onLine === false) {
          reject({ message: "Blocked signout when offline" });
          return;
        }
        window.addEventListener("handleAuth.signOut", ((
          event: CustomEvent<SignOutEventResponse>
        ) => {
          event.detail.status === "ok"
            ? resolve()
            : reject({ message: event.detail.message });
        }) as EventListener);
        window.webkit?.messageHandlers.handleAuth.postMessage({
          action: "signOut",
          payload: { endpoint: fetchUrl },
        });
      });
      Cookies.remove("auth-token");
      return;
    } catch (err) {
      return thunkAPI.rejectWithValue(err as Error);
    }
  },
  web: async ({ thunkAPI, fetchUrl }: SignOutProps) => {
    try {
      const response = await window.fetch(fetchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
      });
      if (!response.ok) {
        const { message = "signOut failed" } = await response.json();
        throw new Error(message);
      }
      return;
    } catch (err) {
      return thunkAPI.rejectWithValue(err as Error);
    }
  },
};
