import {
  createSlice,
  createAsyncThunk,
  type Action,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";
import { replaceStructure } from "Store/structure";
// import { replaceSlides } from "Store/slides";
import { signOut } from "Store/auth/thunks";
import { syncRemoteConfig, role } from "config";
import { generateRoomCode } from "utilities";
import type { AppDispatch, RootState } from "Store";

export let socket: Socket | undefined;

export const openSocket = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>("syncRemote/openSocket", async (params, { dispatch, getState }) => {
  if (syncRemoteConfig === undefined) {
    console.error("Cannot open a remote socket without `syncRemoteConfig`");
    return;
  }
  socket = io(`${syncRemoteConfig.apiUrl}/${process.env.REACT_APP_NAMESPACE}`);

  dispatch(setStarted(true));
  dispatch(setFinished(false));

  socket.on("connect", () => {
    dispatch(setConnected(true));

    // join the room
    const { nickname, room } = getState().syncRemote;
    socket?.emit("join", { nickname, room, role });

    socket?.emit("host-starts-presentation", { room });

    if (role === "broadcast") {
      // when host enters room, send presentation state to room
      const { structure, slides } = getState();
      socket?.emit("send-state", { room, state: { structure, slides } });
    }
  });

  socket.io.on("error", (error: unknown) => {
    if (error === "Error: xhr poll error") {
      dispatch(setConnected(false));
    } else {
      console.log("error (other than `Error: xhr poll error`)", error);
    }
  });

  socket.io.on("reconnect", () => {
    dispatch(setConnected(true));
    const { room, nickname } = getState().syncRemote;
    socket?.emit("reconnect", { room, nickname, role });
  });

  socket.on("disconnect", () => {
    dispatch(setConnected(false));
  });

  if (role === "listen") {
    // handle Redux actions
    socket.on("action", (action: Action) => {
      if (
        action.type.startsWith("structure/") ||
        action.type.startsWith("slides/")
      ) {
        dispatch(action);
      }
    });
  }

  // handle state-sync request/response
  if (role === "broadcast") {
    // when viewer joins/reconnects, sync-server emits `request-state` for that user
    socket.on("request-state", ({ requester_id }) => {
      const { structure, slides } = getState();
      socket?.emit("send-state", {
        recipient_id: requester_id,
        state: { structure, slides },
      });
    });
  } else if (role === "listen") {
    // when host responds to `request-state`, viewer receives state
    socket.on("receive-state", ({ structure, slides }) => {
      dispatch(replaceStructure(structure));
      // NB: disabling this temporarily :(
      // Now that data comes from the API and we're setting media base URLs, an iPad will send the wrong base URL to media, thereby breaking all media
      // dispatch(replaceSlides(slides));
    });
  }

  if (role === "listen") {
    socket.on("presentation-started", () => {
      dispatch(setFinished(false));
    });
    socket.on("presentation-finished", () => {
      dispatch(setFinished(true));
    });
  }

  socket.on("room-manifest", (manifest: ManifestEntries) => {
    dispatch(setManifest(manifest));
  });
});

export const closeSocket = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>("syncRemote/closeSocket", async (params, { dispatch, getState }) => {
  dispatch(setStarted(false));
  dispatch(setFinished(true));

  if (!socket) return;

  const { nickname, room } = getState().syncRemote;
  socket.emit("leave", { nickname, room });
  if (role === "broadcast") {
    socket.emit("host-ends-presentation", { room });
  }
  socket.io.reconnection(false);
  socket.disconnect();
  socket = undefined;
});

type ManifestEntries = Array<{
  nickname: string;
  role: "broadcast" | "listen";
}>;

type SyncRemoteState = {
  started: boolean;
  finished: boolean;
  connected: boolean;
  nickname: string;
  room: string;
  manifest: ManifestEntries;
};

const initialState: SyncRemoteState = {
  started: false,
  finished: false,
  connected: false,
  nickname:
    process.env.REACT_APP_NAMESPACE?.replace(/([A-Z])/g, " $1").trim() ??
    "Anonymous",
  room: role === "broadcast" ? generateRoomCode() : "",
  manifest: [],
};

const syncRemoteSlice = createSlice({
  name: "syncRemote",
  initialState,
  reducers: {
    reset(state) {
      state.started = initialState.started;
      state.finished = initialState.finished;
      state.connected = initialState.connected;
      state.nickname = initialState.nickname;
      state.room = initialState.room;
      state.manifest = initialState.manifest;
    },
    setStarted(state, action: PayloadAction<boolean>) {
      state.started = action.payload;
    },
    setFinished(state, action: PayloadAction<boolean>) {
      state.finished = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.connected = action.payload;
    },
    setNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload;
    },
    setRoom(state, action: PayloadAction<string>) {
      state.room = action.payload;
    },
    setManifest(state, action: PayloadAction<ManifestEntries>) {
      state.manifest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => [signOut.fulfilled, signOut.rejected].includes(action.type),
      (state, action) => {
        reset();
      }
    );
  },
});

export const {
  setStarted,
  setFinished,
  setConnected,
  setNickname,
  setRoom,
  setManifest,
  reset,
} = syncRemoteSlice.actions;

export const selectScreenshareActive = (state: RootState) =>
  state.syncRemote.started === true && state.syncRemote.connected === true;

export const selectScreenshareRoom = (state: RootState) =>
  state.syncRemote.room;

export default syncRemoteSlice;
