export const getPersistedState = <T>(
  key: string,
  defaultState: T = {} as T
): T => {
  if (typeof window === "undefined") return defaultState;
  try {
    const item = window.localStorage.getItem(key);
    if (item === null) {
      window.localStorage.setItem(key, JSON.stringify(defaultState));
      return defaultState;
    }
    return JSON.parse(item);
  } catch (err) {
    console.error(err);
    return defaultState;
  }
};
