import * as Sentry from "@sentry/react";
import type {
  UntransformedPresentation,
  Stories,
  ColumnGroup,
  Column,
  Sequences,
  Sequence,
} from "Store/stories";

import { transformApiResponseSlide } from "@showhereco/ui";

type TransformApiResponseProps = {
  presentations: Array<UntransformedPresentation>;
  mediaBaseUrlTemplate: string;
  screenCapsBaseUrlTemplate: string;
};

export function TransformApiResponse({
  presentations,
  mediaBaseUrlTemplate,
  screenCapsBaseUrlTemplate,
}: TransformApiResponseProps) {
  let stories: Stories = [];
  try {
    if (!Array.isArray(presentations))
      throw new Error(
        "[TransformApiResponse] `presentations` must be an array"
      );
    if (presentations.length === 0)
      throw new Error("[TransformApiResponse] No presentations found");

    stories = presentations
      .filter(({ name, id, latestCompiledPresentationData: publishedData }) => {
        if (!publishedData) {
          console.error(
            `Missing compiled presentation data for "${name}" (id: ${id})`
          );
          return false;
        }
        if (!publishedData.mediaManifest) {
          console.error(`Missing media manifest for "${name}" (id: ${id})`);
          return false;
        }
        return true;
      })
      .sort((a, b) => a.order - b.order)
      .map((p) => {
        const mediaBaseUrl = mediaBaseUrlTemplate
          .replace("#PRES_ID#", `${p.id}`)
          .replace(
            "#PRES_VERSION#",
            `${p.latestCompiledPresentationData?.version ?? 1}`
          );

        let storyThumbnail = null;
        const bannerMedia =
          p.latestCompiledPresentationData?.body.settingsData?.bannerMedia;
        if (bannerMedia?.length > 0) {
          storyThumbnail = `${mediaBaseUrl}${bannerMedia[0].fileName}`;
        }

        const navThumbs: Record<number, string> = [];
        const screenCapsBaseUrl = screenCapsBaseUrlTemplate
          .replace("#PRES_ID#", `${p.id}`)
          .replace(
            "#PRES_VERSION#",
            `${p.latestCompiledPresentationData?.version ?? 1}`
          );
        p.screenCapManifest?.forEach(({ slideId, screenCapFilename }) => {
          if (!navThumbs[slideId]) {
            navThumbs[slideId] = `${screenCapsBaseUrl}${screenCapFilename}`;
          }
        });

        const columnGroups: Array<ColumnGroup> =
          p.latestCompiledPresentationData.body.columnGroups.map((cg) => ({
            id: cg.id,
            name: cg.name,
            columns: cg.columns.map((col) => {
              const column: Column = {
                id: col.id,
                thumbnail: col.thumbnail
                  ? `${mediaBaseUrl}${col.thumbnail.fileName}`
                  : null,
                slides: col.slides?.length
                  ? col.slides.map((slide) =>
                      transformApiResponseSlide({ slide, mediaBaseUrl })
                    )
                  : [],
              };
              return column;
            }),
          }));

        const sequences: Sequences = p.sequences
          .filter(({ version }) => version !== null)
          .filter(({ slides }) => slides?.length > 0)
          .sort((a, b) => {
            // sort by newest to oldest
            const aTime = new Date(a.updatedAt).getTime();
            const bTime = new Date(b.updatedAt).getTime();
            return bTime - aTime;
          })
          .map((sequence) => {
            const shapedSequence: Sequence = {
              id: sequence.id,
              accountId: p.accountId,
              projectId: p.projectId,
              version: sequence.version ?? -1,
              name: sequence.name,
              description: sequence.description ?? "",
              publishedAt: sequence.updatedAt,
              slides:
                sequence.slides?.map((slide) => ({
                  ...transformApiResponseSlide({
                    slide,
                    mediaBaseUrl,
                  }),
                  thumbnail: navThumbs[slide.id],
                })) ?? [],
              disabled: sequence.contentStatus !== "published",
              isPublishing: sequence.contentStatus === "publishing",
            };
            return shapedSequence;
          });
        return {
          accountId: p.accountId,
          projectId: p.projectId,
          id: p.id,
          version: p.latestCompiledPresentationData.version,
          publishedAt: p.latestCompiledPresentationData.updatedAt,
          order: p.order,
          downloadSize: {
            media: p.mediaSize,
            screenCapThumbs: p.screencapsSize,
          },
          archived: p.archived,
          title: p.name,
          thumbnail: storyThumbnail,
          columnGroups,
          sequences,
        };
      });
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }

  return stories;
}
