import { useState, type PropsWithChildren } from "react";
import { useAppDispatch, useAppSelector } from "Store";
import {
  setNickname,
  setRoom,
  openSocket,
  closeSocket,
} from "Store/syncRemote";
import {
  PresenterButton as Button,
  IconClose,
  IconArrowForward,
} from "@showhereco/ui";
import styles from "./Join.module.css";

function Join(props: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const { connected, nickname, room } = useAppSelector(
    (state) => state.syncRemote
  );
  const [nicknameValue, setNicknameValue] = useState(nickname);

  if (connected) {
    return (
      <>
        {props.children}
        <Button
          icon={IconClose}
          iconPosition="right"
          text="Leave meeting"
          onClick={() => dispatch(closeSocket())}
          position="top-right"
          theme="black"
          size={{ width: 240, height: 60 }}
        />
      </>
    );
  }

  const joinButtonDisabled = nicknameValue === "" || room?.length !== 4;

  return (
    <div className={styles.container}>
      <div className={styles.container__inner}>
        <label className={styles.input_label}>Your name</label>
        <input
          className={styles.nickname}
          type="text"
          onChange={(ev) => setNicknameValue(ev.target.value)}
        />

        <label className={styles.input_label}>Meeting number</label>
        <input
          className={styles.pin_input}
          maxLength={4}
          pattern="[0-9]*"
          value={room}
          onChange={(ev) => dispatch(setRoom(ev.target.value))}
        />

        <Button
          onClick={() => {
            dispatch(setNickname(nicknameValue));
            dispatch(openSocket());
          }}
          size={{ width: "100%", height: "60px" }}
          text="Enter meeting"
          icon={IconArrowForward}
          iconPosition="right"
          theme={joinButtonDisabled ? "grey-dark" : "green"}
          disabled={joinButtonDisabled}
        />
      </div>
      <p className={styles.credit}>
        Powered by{" "}
        <a href="https://showhere.co" target="_blank" rel="noopener noreferrer">
          Showhere
        </a>
      </p>
    </div>
  );
}

export default Join;
